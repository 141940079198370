/* Base Dependencies */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
/* General */
import Options from '../../Options';
import { 
  getLargestVariable,
  getSegmentByCode,
  getTopicVariables,
  getVariableCompareData,
  getKeyFeatures,
  getOverviewData
} from '../../Utils';
/* Pages */
import Page404 from '../Page404/Page404';
/* Components */
import SegmentCard from '../../components/SegmentCard/SegmentCard';
import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';
import SegmentPageTitleBar from '../../components/SegmentPageTitleBar/SegmentPageTitleBar';
import SegmentPageHero from '../../components/SegmentPageHero/SegmentPageHero';
import SegmentPageOverview from '../../components/SegmentPageOverview/SegmentPageOverview';
import VariableWidget from '../../components/VariableWidget/VariableWidget';
import VariableView from '../../components/VariableView/VariableView';
import VariableCompare from '../../components/VariableCompare/VariableCompare';
/* Interfaces */
import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ISegmentOverviewData from '../../interfaces/ISegmentOverviewData';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISuperGroupBasic from '../../interfaces/ISuperGroupBasic';
import ISegment from '../../interfaces/ISegment';
import ITopicVariable from '../../interfaces/ITopicVariable';
import ISegmentKeyFeatures from '../../interfaces/ISegmentKeyFeatures';
import IVariableCompareData from '../../interfaces/IVariableCompareData';
import { IVariableWidgetConfig, IVariableWidgetData } from '../../interfaces/IVariableWidget';
/* Enums */
/* Data */
import { whoWeAreWidgetsConfig } from '../../data/whoWeAreWidgetsConfig';
/* Styles */
import './SegmentPage.css';

export default function SegmentPage(props: ISegmentPageProps) {
  const params : ISegmentPageParams = useParams();
  const isSuperGroup = params.code[0] === '*';
  const segmentOverview : ISegmentBasic | ISuperGroupBasic | undefined = isSuperGroup ? getSegmentByCode(params.code, props.supergroups) : getSegmentByCode(params.code, props.segments);

  const [segment, setSegment] = useState<ISegment | null | false>(null);
  const [overviewData, setOverviewData] = useState<ISegmentOverviewData | null>(null);
  const [keyFeatures, setKeyFeatures] = useState<ISegmentKeyFeatures | null>(null);
  const [exposureChannels, setExposureChannels] = useState<ITopicVariable[] | null>(null);

  const [variableViewData, setVariableViewData] = useState<ITopicVariable[] | null>(null);
  const [variableCompareData, setVariableCompareData] = useState<IVariableCompareData | null>(null);

  const [whoWeAreWidgetsData, setWhoWeAreWidgetsData] = useState<IVariableWidgetData[] | null>(null);
  const [whoWeAreWidgets] = useState<IVariableWidgetConfig[]>(whoWeAreWidgetsConfig);

  useEffect(() => {
    async function getSegment(code : string) {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/${isSuperGroup ? 'supergroups' : 'segments'}/${code}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        let segment : ISegment = await response.json();
        setSegment(segment);
      } else {
        setSegment(false);
      }
    }
    getSegment(params.code);

    if(!isSuperGroup) {
      // Saving segment view
      const customHeaders = Object.assign(Options.ApiHeaders);
      customHeaders.set('Content-Type', 'application/json');
      fetch(`${Options.ApiBase}/wp-json/experian/segments/recently-viewed`, {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify({
          code: params.code
        })
      });

    }
  }, [params.code, isSuperGroup]);

  useEffect(() => {
    if(segment === null || segment === false) return;
    getOverviewData(params.code).then(data => setOverviewData(data));
    getExposureChannels(params.code);
    if(isSuperGroup) return;
    (async () => {
      setKeyFeatures(await getKeyFeatures(params.code));
    })();
  }, [params.code, segment, isSuperGroup]);

  useEffect(() => {
    if(segment === null || segment === false) return;
    getWhoWeAreWidgets(params.code, whoWeAreWidgets);
  }, [whoWeAreWidgets, params.code, segment])

  useEffect(() => {
    if(variableViewData === null) return;
    scroller.scrollTo('SegmentPage__expanded-view', {
      duration: 500,
      delay: 0,
      smooth: "ease",
    });
  }, [variableViewData])

  useEffect(() => {
    if(variableCompareData === null) return;
    scroller.scrollTo('SegmentPage__expanded-compare', {
      duration: 500,
      delay: 0,
      smooth: "ease",
    });
  }, [variableCompareData])

  // If segment is not in the list it probably doesn't exist
  if(segmentOverview === undefined) return <Page404 />;

  async function getExposureChannels(code : string) {
    setExposureChannels(await getTopicVariables(code, 'Channel Exposure'));
  }

  async function getWhoWeAreWidgets(code : string, whoWeAreWidgets : IVariableWidgetConfig[]) {
    const promises : Promise<any>[] = [];
    
    whoWeAreWidgets.forEach((w, idx) => {
      promises.push(getTopicVariables(code, w.variableName));
    });
    const data : IVariableWidgetData[] = (await Promise.all(promises)).map(d => { return { variableData: d } });
    setWhoWeAreWidgetsData(data);
  }

  if(segment === null) return (
    <div className="SegmentPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />
      <strong>Loading...</strong>
    </div>
  );
  if(segment === false) return <Page404 />;

  return (
    <div className="SegmentPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />
      <SegmentPageHero name={segment.name} description={segment.description} heroImage={segment.hero_image} overviewData={overviewData} />
      <SegmentPageOverview keyFeatures={keyFeatures} suburbs={segment.suburbs} exposureChannels={exposureChannels} />

      {(isSuperGroup) ?
      <section className="container page-section SegmentPage__sub-segments">
        <h5 className="SegmentPage__title">Super Group Segments</h5>
        <div className="SegmentPage__sub-segments-container">
          {props.segments.filter(s => (segmentOverview as ISuperGroupBasic).segments.indexOf(s.code) !== -1).map(segment => <SegmentCard className="SegmentPage__segment-card" key={segment.id} segment={segment} />)}
        </div>
      </section> : null}

      {whoWeAreWidgetsData !== null ? 
      <section className="container page-section SegmentPage__who-we-are">
        <h5 className="SegmentPage__title">Who We Are</h5>
        <div className="grid">
        {whoWeAreWidgets.map((w, idx) => {
          //if(!(idx in whoWeAreWidgetsData)) return null;
          return (
            <div key={w.variableName} className="grid__col--span3">
              <VariableWidget data={getLargestVariable(whoWeAreWidgetsData[idx].variableData)} variant={w.variant} onPress={() => setVariableViewData(whoWeAreWidgetsData[idx].variableData)} />
            </div>
          )
        })}
        </div>
      </section> : null}

      <div className="print__page-break" />

      {variableViewData !== null ?
      <section id="SegmentPage__expanded-view" className="SegmentPage__expanded-view container page-section">
        <h5 className="SegmentPage__title">Expanded View</h5>
        <VariableView data={variableViewData} onVariableSelected={async (id) => setVariableCompareData(await getVariableCompareData(id))}/>
      </section> : null}

      <div className="print__page-break" />

      {variableCompareData !== null ?
      <section id="SegmentPage__expanded-compare" className="SegmentPage__expanded-compare container page-section">
        <VariableCompare data={variableCompareData} />
      </section>: null}
    </div>
  );
}
