import { 
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import ReactWordcloud, {
  Scale as ReactWordcloudScale,
  Spiral as ReactWordcloudSpiral
} from 'react-wordcloud';

import Options from '../../Options';
import ChartColors from '../../constants/ChartColors';
import { 
  getSegmentByCode
} from '../../Utils';

import Page404 from '../Page404/Page404';
/* Components */
import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';
import SegmentPageTitleBar from '../../components/SegmentPageTitleBar/SegmentPageTitleBar';
import ActionsBar from '../../components/ActionsBar/ActionsBar';

import Accordion from '../../elements/Accordion/Accordion';
import AccordionItem from '../../elements/Accordion/AccordionItem';

import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISegmentDescription from '../../interfaces/ISegmentDescription';

import './SegmentDescriptionPage.css'

const rotationAngles: [number, number] = [0, 90];
const fontSizes: [number, number] = [14, 55];
const wordcloudScale: ReactWordcloudScale = 'sqrt';
const wordcloudSpiral: ReactWordcloudSpiral = 'archimedean';
const wordcloudOptions = {
  colors: ChartColors,
  fontFamily: '"Roboto", "Helvetica Neue", sans-serif',
  enableTooltip: false,
  rotations: 0,
  padding: 1,
  scale: wordcloudScale,
  spiral: wordcloudSpiral,
  rotationAngles,
  fontSizes,

  deterministic: true,
  //transitionDuration: 0
}

export default function SegmentDescriptionPage(props: ISegmentPageProps) {
  const params : ISegmentPageParams = useParams();
  const segmentOverview : ISegmentBasic | undefined = getSegmentByCode(params.code, props.segments);
  const [description, setDescription] = useState<ISegmentDescription | null>(null);

  useEffect(() => {
    if(segmentOverview === undefined) return;
    (async function() {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/segments/${params.code}/description`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data = await response.json();
        setDescription(data);
      }
    })();
  }, [params.code, segmentOverview]);

  if(props.segments.length === 0) return (
    <div className="SegmentDescriptionPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <strong>Loading...</strong>
    </div>
  );

  // If segment is not in the list it probably doesn't exist
  if(segmentOverview === undefined) return <Page404 />;

  function renderWordCloud() {
    if(description === null) return null;
    console.log('wordcloud re-render');
    return <div className="SegmentDescriptionPage__wordcloud"><ReactWordcloud options={wordcloudOptions} words={description.wordcloud.map(w => { return { text: w.cloud_word, value: w.value_capped }; })} /></div>;
  }

  return (
    <div className="SegmentDescriptionPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />

      <section className="SegmentDescriptionPage__section container page-section page-section--top-space">
        <div className="SegmentDescriptionPage__content content-block">
          <div className="SegmentDescriptionPage__top-bar">
            <ActionsBar />
          </div>
          {description !== null ?
          <Accordion>
            <AccordionItem title="Wordcloud">
              {renderWordCloud()}
              {/*<ReactWordcloud options={wordcloudOptions} words={description.wordcloud.map(w => { return { text: w.cloud_word, value: w.value_capped }; })} />*/}
            </AccordionItem>
            <AccordionItem title="Why we matter">
              {description.why_we_matter}
            </AccordionItem>
            <AccordionItem title="Who we are">
              {description.who_we_are}
            </AccordionItem>
            <AccordionItem title="Where we live">
              {description.where_we_live}
            </AccordionItem>
            <AccordionItem title="What we do">
              {description.what_we_do}
            </AccordionItem>
            <AccordionItem title="How to reach us">
              {description.how_to_reach_us}
            </AccordionItem>
            <AccordionItem title="How to speak to us">
              {description.how_to_speak_to_us}
            </AccordionItem>
          </Accordion>
          : null}
        </div>
      </section>
    </div>
  );
}
