import { 
  useState,
  useCallback,
  useMemo
} from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import Chart, { ChartEvent } from 'chart.js/auto';

import { sortByKey } from '../../Utils';
import { fontFamily } from '../../Options';

import ITopicVariable from '../../interfaces/ITopicVariable';
import IDataSelectorProps from '../../interfaces/IDataSelectorProps';
import VariableSortOptions from '../../enums/VariableSortOptions';

import ChartColors from '../../constants/ChartColors';

import DataSelector from '../../components/DataSelector/DataSelector';
import ActionsBar from '../../components/ActionsBar/ActionsBar';

import {ReactComponent as SearchIcon} from '../../images/feather-icons/search.svg';

import './VariableView.css';

enum ChartKind {
  Pie,
  Bar
}

enum DataKind {
  Means,
  Index
}

interface IVariableViewProps extends IDataSelectorProps {
  data: ITopicVariable[],
}

function makeGradients(canvasWidth: number) {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  if(ctx === null) throw new Error('Can\'t create canvas context!');
  //if(ctx === null) return [null, null];

  const underGradient = ctx.createLinearGradient(canvasWidth/2, 0, canvasWidth, 0);
  underGradient.addColorStop(0, "#EAD200");
  underGradient.addColorStop(1, "#56C45A");
  const overGradient = ctx.createLinearGradient(150, 0, canvasWidth/2, 0);
  overGradient.addColorStop(0, "#EA0000");
  overGradient.addColorStop(1, "#F0AD00");

  return [underGradient, overGradient];
}
const [underGradient, overGradient] = makeGradients(500);

export default function VariableView(props: IVariableViewProps) {
  const [sorting, setSorting] = useState<VariableSortOptions>(VariableSortOptions.Default);
  const [chartKind, setChartKind] = useState<ChartKind>(ChartKind.Bar);
  const [dataKind, setDataKind] = useState<DataKind>(DataKind.Index);

  const [filterString, setFilterString] = useState<string>('');

  const filteredData = useMemo(() => {
    let data = [...props.data];

    // Pie chart shouldn't show average values
    if(chartKind === ChartKind.Pie) {
      data = data.filter(a => a.variable.indexOf('Average') !== 0);
    }

    if(sorting === VariableSortOptions.Rank) {
      if(chartKind === ChartKind.Pie || dataKind === DataKind.Means) {
        data = data.sort((a, b) => sortByKey(a, b, 'mean'));
      } else {
        data = data.sort((a, b) => sortByKey(a, b, 'indice'));
      }
    }

    return data;
  }, [props.data, chartKind, dataKind, sorting]);
  
  // // removing average value
  // let filteredData = props.data.filter(a => a.variable.indexOf('Average') !== 0);
  // //let filteredData = props.data.filter(a => a.mean <= 100);

  // if(dataKind === DataKind.Index && chartKind === ChartKind.Bar) {
  //   filteredData = props.data;
  // }

  // if(sorting === VariableSortOptions.Rank) {
  //   filteredData = filteredData.sort((a, b) => sortByKey(a, b, 'indice'));
  // } else {
  //   filteredData = filteredData.sort((a, b) => sortByKey(a, b, 'mean'));
  // }

  const chartClickHandler = useCallback(function (event: ChartEvent, array: any) {
    if(props.onVariableSelected === null || props.onVariableSelected === undefined) return;
    //console.log(event, array);
    const variable : ITopicVariable = filteredData[array[0].index];
    props.onVariableSelected(variable.id);
  }, [props, filteredData]);

  const pluginsLegendPostion: "left" | "top" | "right" | "bottom" | "center" | "chartArea" = 'left';
  const pluginsAlignPosition: "center" | "start" | "end" = 'start';
  const pieOptions = useMemo(() =>{ return {
    maintainAspectRatio: false,
    hoverOffset: 30,
    layout: {
      padding: {
        top: 15, bottom: 15
      }
    },
    //radius: '50%'
    plugins: {
      legend: {
        position: pluginsLegendPostion,
        align: pluginsAlignPosition
      },
      tooltip: {
        callbacks: {
          label: function(context : any) {
            var label = context.label || '';
            if(label) label += ': ';                              
            if(context.parsed.y !== null) {
              label += context.parsed;// + '%';
            }
            return label;
          }
        }
      }
    },
    onClick: chartClickHandler
  }; }, [chartClickHandler]);

  // let barOptions: any = null;
  // if(dataKind === DataKind.Means) {
  //   barOptions = barMeansOptions;
  // } else {
  //   barOptions = barIndexOptions;
  // }

  const barOptions = useMemo(() => {
    if(dataKind === DataKind.Means) {
      return {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context : any) {
                var label = context.label || '';
                if(label) label += ': ';                              
                if(context.parsed.y !== null) {
                  label += context.parsed.y;// + '%';
                }
                return label;
              }
            }
          }
        },
        onClick: chartClickHandler
      };
    } else {
      const indexAxis: "y" | "x" = 'y';
      return {
        maintainAspectRatio: false,
        indexAxis,
        barPercentage: 1,
        categoryPercentage: 0.85,
        borderWidth: 0,
        base: 100,
        scales: {
          x: {
            max: 200,
            ticks: {
              color: '#111',
              font: {
                size: 16,
                weight: 'bold',
                family: fontFamily
              },
              stepSize: 100
            },
            grid: {
              display: false,
              borderWidth: 0
            }
          },
          y: {
            ticks: {
              color: '#111',
              font: {
                size: 12,
                weight: 'bold',
                family: fontFamily
              },
              padding: 20 
            },
            grid: {
              offset: false,
              color: '#f0f0f0',
              borderWidth: 0,
              drawBorder: false,
              lineWidth: (e: any) => {
                return e.chart.getDatasetMeta(0).data[0].height;
              },
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context : any) {
                var label = context.label || '';
                if(label) label += ': ';                              
                if(context.parsed.y !== null) {
                  label += context.parsed.x;
                }
                return label;
              }
            }
          }
        },
        onClick: chartClickHandler
      };

    }
  }, [dataKind, chartClickHandler]);


  // const data = useCallback(function(canvas : HTMLCanvasElement) {
  //   return {
  //     labels: filteredData.map(v => v.variable),
  //     datasets: [
  //       {
  //         label: filteredData.length > 0 ? filteredData[0].topic : '',
  //         data: filteredData.map(v => (dataKind === DataKind.Means ? v.mean : v.indice)),
  //         backgroundColor: (chartKind === ChartKind.Bar && dataKind === DataKind.Index) ? 
  //           filteredData.map(v => v.indice < 100 ? overGradient : underGradient) 
  //           : 
  //           ChartColors,
  //       },
  //     ],
  //   };
  // }, [filteredData, dataKind, chartKind]);

  const data = useMemo(() => { return {
    labels: filteredData.map(v => v.variable),
    datasets: [
      {
        label: filteredData.length > 0 ? filteredData[0].topic : '',
        data: filteredData.map(v => (dataKind === DataKind.Means ? v.mean : v.indice)),
        backgroundColor: (chartKind === ChartKind.Bar && dataKind === DataKind.Index) ? 
          filteredData.map(v => v.indice < 100 ? overGradient : underGradient) 
          : 
          ChartColors,
      },
    ],
  };}, [filteredData, dataKind, chartKind])

  const plugins = useMemo(() => [
    {
      id: 'expn_variable_view_labels',
      afterDraw: function(chart: Chart) {
        //console.log(this);
        const chartInstance : any = chart;
        const ctx = chartInstance.ctx;

        ctx.font = fontFamily;

        const canvasWidth = ctx.canvas.width;
        chartInstance.data.datasets.forEach(function (dataset: any, i : number) {
          if(!Number.isInteger(dataset.data[0])) return;
          var meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach(function(bar: any, index: number) {
            var data = dataset.data[index];                            
            if(Math.abs(data - 100) < 10) {
              ctx.fillStyle = '#111';
            } else {
              ctx.fillStyle = 'white';
            }
            ctx.fillText(data, Math.min(data > 100 ? bar.x - 30 : bar.x + 2, canvasWidth - 50), bar.y + 5);
          });
        });

      }
    }
  ], []);

  function getDataSelectorVariables() {
    if(props.dataSelectorVariables === undefined) return [];

    if(filterString !== '') {
      const searchString = filterString.toLowerCase();
      const filteredVariables = props.dataSelectorVariables.filter(v => v.topic.toLowerCase().indexOf(searchString) !== -1 || v.category.toLowerCase().indexOf(searchString) !== -1);
      return filteredVariables;
    }

    return props.dataSelectorVariables;
  }

  const renderChart = useMemo(() => {
    console.log('chart re-render');
    return chartKind === ChartKind.Pie ?
     <Pie className="chart-block__chart" data={data} options={pieOptions} />
     : 
     <Bar className="chart-block__chart" data={data} options={barOptions} plugins={plugins} />;
  }, [chartKind, data, pieOptions, barOptions, plugins]);

  return (
    <div className="VariableView content-block chart-block">
      <div className="chart-block__headers grid">
        { props.showDataSelector !== undefined ?
        <div className="chart-block__data-selector-header grid__col--span3">
          <h5 className="VariableView__title">Select Data</h5>
        </div>
        : null}
        <div className={"chart-block__chart-header" + (props.showDataSelector !== undefined ? ' grid__col--span6' : ' grid__col--span9')}>
          <h5 className="VariableView__title">{props.data[0].topic}</h5>
          <ActionsBar />
        </div>
        <div className="chart-block__filter-header grid__col--span3">
          <h5 className="VariableView__title">Chart Options</h5>
        </div>
      </div>
      <div className="chart-block__content grid">
        { props.showDataSelector !== undefined && props.dataSelectorVariables !== undefined ?
        <div className="chart-block__data-selector-content grid__col--span3">
          <div className="VariableView__DataSelector-search search-field input-group">
            <input className="search-field__input input" name="filter-string" placeholder="Search..." value={filterString} onChange={(e) => setFilterString(e.target.value)} />
            <button className="search-field__btn" type="submit"><SearchIcon /></button>
          </div>

          <div className="VariableView__DataSelector-container condensed-scroll">
            <DataSelector variables={getDataSelectorVariables()} selectedCategory={props.data[0].category} selectedTopic={props.data[0].topic} selectedVariable={-1} onTopicSelected={props.onTopicSelected} />
          </div>
        </div>
        : null}
        <div className={"chart-block__chart-content" + (props.showDataSelector !== undefined ? ' grid__col--span6' : ' grid__col--span9')}>
          {/*chartKind === ChartKind.Pie ?
          <Pie className="chart-block__chart" data={data} options={pieOptions} />
            : 
            <Bar className="chart-block__chart" data={data} options={barOptions} plugins={plugins} />
          */}
          {renderChart}
        </div>
        <div className="chart-block__filter-content grid__col--span3">
          <div className="side-options__label">Rank</div>
          <div className="btn-group">
            <button onClick={() => setSorting(VariableSortOptions.Default)} className={"btn" + (sorting === VariableSortOptions.Default ? ' btn--purple' : '') }>Default</button>
            <button onClick={() => setSorting(VariableSortOptions.Rank)} className={"btn" + (sorting === VariableSortOptions.Rank ? ' btn--purple' : '') }>Rank</button>
          </div>

          <div className="side-options__label">Chart Type</div>
          <button onClick={() => {setChartKind(ChartKind.Bar); setDataKind(DataKind.Means);}} className={"side-options__full-btn btn" + (chartKind === ChartKind.Bar && dataKind === DataKind.Means ? ' btn--purple' : '')}>Bar: Means</button>
          <button onClick={() => {setChartKind(ChartKind.Bar); setDataKind(DataKind.Index);}} className={"side-options__full-btn btn" + (chartKind === ChartKind.Bar && dataKind === DataKind.Index ? ' btn--purple' : '')}>Bar: Index</button>
          <button onClick={() => {setChartKind(ChartKind.Pie); setDataKind(DataKind.Means);}} className={"side-options__full-btn btn" + (chartKind === ChartKind.Pie ? ' btn--purple' : '')}>Pie</button>
        </div>
      </div>
    </div>
  )
}
