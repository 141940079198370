import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';

import IUser from '../../interfaces/IUser';

import logo from '../../images/logo.png';
//import userpic from '../../images/userpic.jpg';

import './Header.css';

interface IHeaderProps {
  user: IUser | null,
  onLogout: () => void
};


function Header(props: IHeaderProps) {
  const history = useHistory();

  function logoutUser() {
    props.onLogout();
    history.push('/login');
  }

  return (
    <header className="Header">
      <div className="container">
        <Link className="Header__logo-container" to="/"><img src={logo} className="Header__logo" alt="Experian" /></Link>
        <nav className="Header__menu">
          <NavLink className="Header__menu-link" activeClassName="Header__menu-link--active" exact to="/">Dashboard</NavLink>
          <NavLink className="Header__menu-link" activeClassName="Header__menu-link--active" to="/identify">Identify</NavLink>
          <NavLink className="Header__menu-link" activeClassName="Header__menu-link--active" to="/segments">Segments</NavLink>
          <NavLink className="Header__menu-link" activeClassName="Header__menu-link--active" to="/compare">Compare</NavLink>
          <NavLink className="Header__menu-link" activeClassName="Header__menu-link--active" to="/about">About Us</NavLink>
          <NavLink className="Header__menu-link" activeClassName="Header__menu-link--active" to="/help">Help</NavLink>
        </nav>
        {props.user !== null ?
        <div className="Header__user">
          <div className="Header__user-details">
            <div className="Header__user-greeting">Hi {props.user.display_name}!</div>
            <div className="Header__user-logout" onClick={logoutUser}>Logout</div>
          </div>
          <img src={props.user.avatar_url} className="Header__user-pic" alt={props.user.display_name} /> 
        </div>
        : <div style={{width: '128px'}}/>}
      </div>
    </header>
  );
}

export default Header;
