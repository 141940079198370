import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Page, 
  Text,
  Image,
  View,
  Document,
  Font,
  PDFViewer
} from '@react-pdf/renderer';

import Options from '../../Options';
import { 
  getLargestVariable,
  getLargestVariableValue,
  getSegmentByCode,
  getTopicVariables,
  getKeyFeatures,
  getOverviewData
} from '../../Utils';

import Page404 from '../Page404/Page404';
import LoadingPage from '../LoadingPage/LoadingPage';

import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';

import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ISegmentOverviewData from '../../interfaces/ISegmentOverviewData';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISuperGroupBasic from '../../interfaces/ISuperGroupBasic';
import ISegment from '../../interfaces/ISegment';
import ISegmentKeyFeatures from '../../interfaces/ISegmentKeyFeatures';
import ITopicVariable from '../../interfaces/ITopicVariable';
import IStateSuburbs from '../../interfaces/IStateSuburbs';
import ISegmentDescription from '../../interfaces/ISegmentDescription';
import { IVariableWidgetConfig, IVariableWidgetData } from '../../interfaces/IVariableWidget';

import ExposureChannelKind from '../../enums/ExposureChannelKind';
import VariableWidgetVariants from '../../enums/VariableWidgetVariants';

import { whoWeAreWidgetsConfig } from '../../data/whoWeAreWidgetsConfig';

import logo from '../../images/logo.png';
import pdfHeroGradient from '../../images/pdf/pdf-hero-gradient.png';
import widgetTopLine from '../../images/pdf/widget-top-line.png';

import stateTAS from '../../images/pdf/state-tas.png';
import stateNT from '../../images/pdf/state-nt.png';
import stateSA from '../../images/pdf/state-sa.png';
import stateNSW from '../../images/pdf/state-nsw.png';
import stateQLD from '../../images/pdf/state-qld.png';
import stateWA from '../../images/pdf/state-wa.png';
import stateVIC from '../../images/pdf/state-vic.png';
import stateNONE from '../../images/pdf/state-none.png';

import iconRadio from '../../images/pdf/icon-radio.png';
import iconSocialMedia from '../../images/pdf/icon-social-media.png';
import iconOutdoor from '../../images/pdf/icon-outdoor.png';
import iconNewspaper from '../../images/pdf/icon-newspaper.png';
import iconInternet from '../../images/pdf/icon-internet.png';
import iconTelevision from '../../images/pdf/icon-television.png';

import iconPieChart from '../../images/pdf/pie-icon.png';
import iconBarChart from '../../images/pdf/bar-chart-icon.png';

import '../../fonts/fonts.d';
import RobotoRegular from '../../fonts/Roboto-Regular.ttf';
import RobotoBold from '../../fonts/Roboto-Bold.ttf';
import RobotoLight from '../../fonts/Roboto-Light.ttf';

import {styles as s} from './PdfReportPage.styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Register font
Font.register({
  family: 'Roboto', fonts: [
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoRegular },
    { src: RobotoBold, fontWeight: 700 },
  ]
});

function getStateImage(state: string) {
  switch(state) {
    case 'TAS':   return stateTAS;
    case 'NT':    return stateNT;
    case 'SA':    return stateSA;
    case 'NSW':   return stateNSW;
    case 'QLD':   return stateQLD;
    case 'WA':    return stateWA;
    case 'VIC':   return stateVIC;
  }
  return stateNONE;
}

interface IPdfWidget {
  style: string,
  label: string,
  value: string,
  mean: number,
  index: number
};

interface IPdfReportDocumentProps {
  isSuperGroup: boolean,
  segmentOverview: ISegmentBasic | ISuperGroupBasic,
  segment: ISegment,
  overviewData: ISegmentOverviewData,
  keyFeatures: ISegmentKeyFeatures | null,
  exposureChannels: ITopicVariable[],
  segments: ISegmentBasic[],
  widgets: IPdfWidget[],
  description: ISegmentDescription | null
};

// Create Document Component
const PdfReportDocument = ({ 
  isSuperGroup,
  segmentOverview,
  overviewData,
  segment,
  keyFeatures,
  exposureChannels,
  segments,
  widgets,
  description
}: IPdfReportDocumentProps) => {
  const states: IStateSuburbs[] = [];
  segment.suburbs.forEach(suburb => {
    let state = states.find(s => s.stateName === suburb.state);
    // If state not on the list yet, creating it
    if(state === undefined) {
      state = {
        stateName: suburb.state,
        suburbs: []
      };
      states.push(state);
    }

    // Adding out suburb to the state
    state.suburbs.push(suburb.suburb.toLowerCase());
  });

  function getExposureChannelValue(kind : ExposureChannelKind) {
    if(exposureChannels === null) return 0;
    const channel = exposureChannels.find(c => c.variable === kind);
    if(channel) return channel.indice;
    return 0;
  }

  const pdfExposureChannels = [
    {
      label: 'Television',
      value: getExposureChannelValue(ExposureChannelKind.Television),
      icon: iconTelevision
    },
    {
      label: 'Internet',
      value: getExposureChannelValue(ExposureChannelKind.Internet),
      icon: iconInternet
    },
    {
      label: 'Newspaper',
      value: getExposureChannelValue(ExposureChannelKind.Newspaper),
      icon: iconNewspaper
    },
    {
      label: 'Outdoor',
      value: getExposureChannelValue(ExposureChannelKind.Outdoor),
      icon: iconOutdoor
    },
    {
      label: 'Social Media',
      value: getExposureChannelValue(ExposureChannelKind.SocialMedia),
      icon: iconSocialMedia
    },
    {
      label: 'Radio',
      value: getExposureChannelValue(ExposureChannelKind.Radio),
      icon: iconRadio
    },
  ];
  
  return (
  <Document>
    <Page size="A4" style={s.page}>
      <View style={s.header} fixed>
        <Image style={s.header__logo} src={logo} />        
      </View>

      <View style={{...s.titleBar, ...{ backgroundColor: segmentOverview.primary_hex }}} fixed>
        <Text style={s.titleBar__code}>{segmentOverview.code}</Text>
        <Text style={s.titleBar__name}>{segmentOverview.name}</Text>
        <Text style={s.titleBar__household}>{segmentOverview.household.toFixed(2)}% Households</Text>
      </View>

      <View style={s.hero}>
        {segment.hero_image ? <Image style={s.heroBackground} src={segment.hero_image} /> : null}
        <Image style={s.heroBackground} src={pdfHeroGradient} />
        <View style={s.heroMain}>
          <Text style={s.heroTitle}>{segmentOverview.name}</Text>
          <Text style={s.heroDescription}>{segmentOverview.description}</Text>
        </View>
        <View style={s.heroStats}>
          <View style={s.heroStat}>
            <Text style={s.heroStatValue}>{getLargestVariableValue(overviewData.age)}</Text>
            <Text style={s.heroStatLabel}>Age</Text>
          </View>
          <View style={s.heroStat}>
            <Text style={s.heroStatValue}>{getLargestVariableValue(overviewData.annualHouseholdIncome.filter(v => v.source === 'Census'))}</Text>
            <Text style={s.heroStatLabel}>Household Income</Text>
          </View>
          <View style={s.heroStat}>
            <Text style={s.heroStatValue}>{getLargestVariableValue(overviewData.propertyTenure)}</Text>
            <Text style={s.heroStatLabel}>Propery Tenure</Text>
          </View>
          <View style={s.heroStat}>
            <Text style={s.heroStatValue}>{getLargestVariableValue(overviewData.remoteness)}</Text>
            <Text style={s.heroStatLabel}>Remoteness</Text>
          </View>
        </View>
      </View>

      <View style={s.containerGrey}>
        <View style={s.propsContainer}>
          {keyFeatures !== null ?
          <View style={s.propsColumn}>
            <Text style={s.propsTitle}>Key Features</Text>
            <View style={{...s.propsContent, ...s.propsContentKeyFeatures}}>
              <View style={s.keyFeature}>
                <View style={s.keyFeatureBullet}></View>
                <Text style={s.keyFeatureText}>{keyFeatures.key_feature1}</Text>
              </View>
              <View style={s.keyFeature}>
                <View style={s.keyFeatureBullet}></View>
                <Text style={s.keyFeatureText}>{keyFeatures.key_feature2}</Text>
              </View>
              <View style={s.keyFeature}>
                <View style={s.keyFeatureBullet}></View>
                <Text style={s.keyFeatureText}>{keyFeatures.key_feature3}</Text>
              </View>
              <View style={s.keyFeature}>
                <View style={s.keyFeatureBullet}></View>
                <Text style={s.keyFeatureText}>{keyFeatures.key_feature4}</Text>
              </View>
              <View style={s.keyFeature}>
                <View style={s.keyFeatureBullet}></View>
                <Text style={s.keyFeatureText}>{keyFeatures.key_feature5}</Text>
              </View>
              <View style={s.keyFeature}>
                <View style={s.keyFeatureBullet}></View>
                <Text style={s.keyFeatureText}>{keyFeatures.key_feature6}</Text>
              </View>
            </View>
          </View> : null}
  
          <View style={s.propsColumn}>
            <Text style={s.propsTitle}>Top Suburbs</Text>
            <View style={{...s.propsContent, ...s.propsContentStates}}>
              {states.map((state, idx) => <View style={{...s.state, ...(idx === 0 ? s.stateFirst : {}), ...(idx === states.length - 1 ? s.stateLast : {})}}>
                <Text style={s.stateName}>{state.stateName}</Text>
                <Text style={s.stateSuburbs}>{state.suburbs.join('\n')}</Text>
                <Image style={s.stateImage} src={getStateImage(state.stateName)} />
              </View>)}
            </View>
          </View>

          <View style={{...s.propsColumn, ...s.propsColumnLast}}>
            <Text style={s.propsTitle}>Channel Exposure</Text>
            <View style={s.exposureChannels}>
              {pdfExposureChannels.map((channel, idx) => <View style={s.exposureChannel}>
                <Text style={s.exposureChannelValue}>{channel.value}</Text>
                <Text style={s.exposureChannelLabel}>{channel.label}</Text>
                <Image style={s.exposureChannelIcon} src={channel.icon} />
              </View>)}
            </View>
          </View>
        </View>

        {segments.length > 0 ?
        <View wrap={false}>
          <Text style={s.sectionTitle}>Super Group Segments</Text>
          <View style={s.segments}>
            {segments.map((segment, idx) => <View style={s.segment}>
              <View style={{...s.segmentTopLine, ...{ backgroundColor: segment.primary_hex }}}></View>
              <View style={s.segmentContent}>
                <Text style={s.segmentCode}>{segment.code}</Text>
                <Text style={s.segmentKind}>{segment.kind}</Text>
                <Text style={s.segmentName}>{segment.name}</Text>
              </View>
              <Text style={s.segmentHousehold}>{segment.household}%</Text>
            </View>)}
          </View>
        </View> : null}

        <View wrap={false}>
          <Text style={s.sectionTitle}>Who We Are</Text>
          <View style={s.whoWeAreWidgets}>
            {widgets.map((widget, idx) => <View style={s.widget}>
              <Image style={s.widgetTopLine} src={widgetTopLine} />
              <Text style={s.widgetLabel}>{widget.label}</Text>
              <Text style={{...s.widgetValue, ...(widget.style === 'large' ? s.widgetValueLarge : {}), ...(widget.style === 'small' ? s.widgetValueSmall : {})}}>{widget.value}</Text>
              <View style={s.widgetStats}>
                <Image style={s.widgetIcon} src={iconPieChart} />
                <Text style={s.widgetMean}>{widget.mean}%</Text>
                <Image style={s.widgetIcon} src={iconBarChart} />
                <Text style={s.widgetIndex}>{widget.index}</Text>
              </View>
            </View>)}
          </View>
        </View>
      </View>

      { !isSuperGroup && description !== null ? 
      <View style={s.containerGrey} break>
        <Text style={s.descriptionTitle}>Why we matter</Text>
        <Text style={s.descriptionText}>{description.why_we_matter}</Text>

        <Text style={s.descriptionTitle}>Who we are</Text>
        <Text style={s.descriptionText}>{description.who_we_are}</Text>

        <Text style={s.descriptionTitle}>Where we live</Text>
        <Text style={s.descriptionText}>{description.where_we_live}</Text>

        <Text style={s.descriptionTitle}>What we do</Text>
        <Text style={s.descriptionText}>{description.what_we_do}</Text>

        <Text style={s.descriptionTitle}>How to reach us</Text>
        <Text style={s.descriptionText}>{description.how_to_reach_us}</Text>

        <Text style={s.descriptionTitle}>How to speak to us</Text>
        <Text style={s.descriptionText}>{description.how_to_speak_to_us}</Text>
      </View> : null}

    </Page>
  </Document>
)};

export default function PdfReportPage(props: ISegmentPageProps) {
  const params : ISegmentPageParams = useParams();
  const query = useQuery();
  const printQueryParam = query.get('print');
  const isSuperGroup = params.code[0] === '*';
  const segmentOverview : ISegmentBasic | ISuperGroupBasic | undefined = isSuperGroup ? getSegmentByCode(params.code, props.supergroups) : getSegmentByCode(params.code, props.segments);

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const [segment, setSegment] = useState<ISegment | null>(null);
  const [overviewData, setOverviewData] = useState<ISegmentOverviewData | null>(null);
  const [keyFeatures, setKeyFeatures] = useState<ISegmentKeyFeatures | null>(null);
  const [exposureChannels, setExposureChannels] = useState<ITopicVariable[] | null>(null);
  const [whoWeAreWidgetsData, setWhoWeAreWidgetsData] = useState<IVariableWidgetData[] | null>(null);
  const [whoWeAreWidgets] = useState<IVariableWidgetConfig[]>(whoWeAreWidgetsConfig);
  const [description, setDescription] = useState<ISegmentDescription | null>(null);

  useEffect(() => {
    if(iframeRef.current === null) return;
    iframeRef.current.addEventListener('load', () => {
      if(iframeRef === null || iframeRef.current === null || iframeRef.current.contentWindow === null) return;
      if(iframeRef.current.src === '') return;
      if(printQueryParam !== 'true') return;
      iframeRef.current.contentWindow.print();
    });
  });

  useEffect(() => {
    if(segmentOverview === undefined) return;

    async function getSegment(code : string) {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/${isSuperGroup ? 'supergroups' : 'segments'}/${code}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        let segment : ISegment = await response.json();
        setSegment(segment);
      } else {
        setSegment(null);
      }
    }
    getSegment(params.code);
  }, [segmentOverview, params.code, isSuperGroup]);

  useEffect(() => {
    if(segment === null) return;
    getOverviewData(params.code).then(data => setOverviewData(data));
    getExposureChannels(params.code);
    if(isSuperGroup) return;
    (async () => {
      setKeyFeatures(await getKeyFeatures(params.code));
    })();
  }, [params.code, segment, isSuperGroup]);

  async function getExposureChannels(code : string) {
    setExposureChannels(await getTopicVariables(code, 'Channel Exposure'));
  }
  
  useEffect(() => {
    if(segment === null) return;
    getWhoWeAreWidgets(params.code, whoWeAreWidgets);
  }, [whoWeAreWidgets, params.code, segment])

  useEffect(() => {
    if(segmentOverview === undefined) return;
    (async function() {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/segments/${params.code}/description`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data = await response.json();
        setDescription(data);
      }
    })();
  }, [params.code, segmentOverview]);

  async function getWhoWeAreWidgets(code : string, whoWeAreWidgets : IVariableWidgetConfig[]) {
    const promises : Promise<any>[] = [];
    
    whoWeAreWidgets.forEach((w, idx) => {
      promises.push(getTopicVariables(code, w.variableName));
    });
    const data : IVariableWidgetData[] = (await Promise.all(promises)).map(d => { return { variableData: d } });
    setWhoWeAreWidgetsData(data);
  }

  if(segmentOverview === undefined) return <Page404 />;

  let segments: ISegmentBasic[] = [];
  if(isSuperGroup) {
    segments = props.segments.filter(s => (segmentOverview as ISuperGroupBasic).segments.indexOf(s.code) !== -1);
  }

  if(segment === null ||
      overviewData === null ||
      exposureChannels === null ||
      whoWeAreWidgetsData === null
  ) return <LoadingPage />;
  
  const widgets = whoWeAreWidgets.map((w, idx) => { 
    const data = getLargestVariable(whoWeAreWidgetsData[idx].variableData);
    return {
      style: (function(variant) {
        switch(variant) {
          case VariableWidgetVariants.Large: return 'large';
          case VariableWidgetVariants.Default: return 'small';
        }
        return 'medium';
      })(w.variant),
      label: data.topic, 
      value: data.variable,
      mean: data.mean,
      index: data.indice
    };
  });

  return (
    <div className="PdfReportPage page">
      <SegmentPageNav segments={props.segments} />

      <section className="container content-block">
        <PDFViewer style={{width: '100%', height: '768px'}} innerRef={iframeRef}>
          <PdfReportDocument isSuperGroup={isSuperGroup} segmentOverview={segmentOverview} segment={segment} overviewData={overviewData} keyFeatures={keyFeatures} exposureChannels={exposureChannels} segments={segments} widgets={widgets} description={description} />
        </PDFViewer>
      </section>
    </div>
  );
}
