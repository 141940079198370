import Chart from 'chart.js/auto';

import { getPluginOption } from './utils';

import SegmentKind from '../enums/SegmentKind';

const treeCurves = {
  id: 'expn_tree_curves',
  beforeDatasetsDraw: function(chart: Chart, args: {}, options: any) {
    const display: boolean = getPluginOption(chart, 'treeCurves', 'display', false);
    console.log('tree curves draw');
    if(!display) return;

    const ctx = chart.ctx;
    const datasetMeta = chart.getDatasetMeta(0);
    if(datasetMeta === null) return;
    const segmentKind = datasetMeta.label.length === 1 ? SegmentKind.Group : SegmentKind.Type;

    const treeSegmentsData = getPluginOption(chart, 'treeCurves', 'treeSegmentsData', null);
    if(treeSegmentsData === null) return;

    // Curves
    Object.keys(treeSegmentsData).filter(k => segmentKind === SegmentKind.Group ? k.length === 1 : k.length > 1).forEach(code => {
      if(treeSegmentsData[code].parent === null) return;
  
      const parentCode = treeSegmentsData[code].parent;
      ctx.beginPath();
      ctx.moveTo(treeSegmentsData[code].left, treeSegmentsData[code].top);
      ctx.strokeStyle = '#ccc';
      ctx.lineWidth = 2;
      if(treeSegmentsData[parentCode] === undefined) console.log('Undefined', parentCode);
      ctx.bezierCurveTo(
        treeSegmentsData[code].left,treeSegmentsData[parentCode].top, 
        treeSegmentsData[parentCode].left,treeSegmentsData[code].top, 
        treeSegmentsData[parentCode].left,treeSegmentsData[parentCode].top
      );
      ctx.stroke();
    });
  }
}

export default treeCurves;
