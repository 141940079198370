const IsDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default class Options {
  static IsDev: boolean = IsDev;

  static ApiBase: string = IsDev ? 'http://localhost:8080' : '';
  static ApiToken: string = '';
  static ApiHeaders: HeadersInit = new Headers();
}

const fontFamily = "'Roboto', 'Helvetica Neue', sans-serif";

export { fontFamily };
