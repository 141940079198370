import React, {
  useState
} from 'react';

import {ReactComponent as TooltipCloseIcon} from '../../images/tooltip-close-icon.svg';

import './Tooltip.css';

interface ITooltipProps {
  children: React.ReactNode,
  text: string,
  width?: number,
  className?: string,
  hover?: boolean
};

export default function Tooltip(props: ITooltipProps) {
  const width = props.width !== undefined ? props.width : 150;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const isHover = props.hover === undefined ? true : props.hover;

  function clickHandler() {
    setIsLocked(!isLocked);
  }

  function mouseOverHandler() {
    if(!isHover) return;
    setIsOpen(true);
  }
  
  function mouseOutHandler() {
    if(!isHover) return;
    setIsOpen(false);
  }

  return (
    <span className={"Tooltip " + (isOpen || isLocked ? ' Tooltip--active' : '') + (props.className !== undefined ? ' ' + props.className : '')}>
      <span onClick={clickHandler} onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}>
        {props.children}
      </span>
    
      <div className="Tooltip__content" style={{ width }}>
        {props.text}
        <button type="button" className="Tooltip__close-btn" onClick={() => setIsLocked(false)}><TooltipCloseIcon /></button>
      </div>
    </span>
  );
}
