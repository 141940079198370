import React from 'react';

export default function imageSliderInit(target: React.RefObject<HTMLElement>) {
  if(target.current === null) return;
  const sliders = target.current.querySelectorAll('.experian-slider');
  sliders.forEach(slider => {
    const slides = Array.from(slider.querySelectorAll('.experian-slider__item'));
    let activeSlide = 0;

    const nextBtn = slider.querySelector('.experian-slider__btn--next');
    const prevBtn = slider.querySelector('.experian-slider__btn--prev');

    if(nextBtn === null || prevBtn === null) return;

    nextBtn.addEventListener('click', function() {
      const newSlide = (activeSlide + 1) % slides.length;
      changeActiveSlide(newSlide);
    });
    
    prevBtn.addEventListener('click', function() {
      const newSlide = (activeSlide - 1) < 0 ? slides.length - 1 : activeSlide - 1;
      changeActiveSlide(newSlide);
    });

    function changeActiveSlide(newActiveSlide: number) {
      slides[activeSlide].classList.remove('experian-slider__item--active');
      slides[newActiveSlide].classList.add('experian-slider__item--active');
      activeSlide = newActiveSlide;
    }
  });
}
