import './SegmentPageTitleBar.css';

interface ISegmentPageTitleBarProps {
  code: string,
  name: string,
  color: string
};

export default function SegmentPageTitleBar(props : ISegmentPageTitleBarProps) {
  return (
    <header className="SegmentPage__title-bar" style={{ backgroundColor: props.color }}>
      <div className="container">
        <h5 className="SegmentPage__title-bar-code">{props.code}</h5>
        <span className="SegmentPage__title-bar-name">{props.name}</span> 
      </div>
    </header>
  )
}
