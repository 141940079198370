import ExposureChannel from '../../components/ExposureChannel/ExposureChannel';
import StateSuburbs from '../../components/StateSuburbs/StateSuburbs';

import ITopicVariable from '../../interfaces/ITopicVariable';
import ISuburb from '../../interfaces/ISuburb';
import IStateSuburbs from '../../interfaces/IStateSuburbs';
import ISegmentKeyFeatures from '../../interfaces/ISegmentKeyFeatures';

import ExposureChannelKind from '../../enums/ExposureChannelKind';

import './SegmentPageOverview.css';

interface ISegmentPageOverviewProps {
  keyFeatures: ISegmentKeyFeatures | null,
  suburbs: ISuburb[] | null,
  exposureChannels: ITopicVariable[] | null
}

export default function SegmentPageOverview(props : ISegmentPageOverviewProps) {
  const states: IStateSuburbs[] = [];
  if(props.suburbs !== null) {
    props.suburbs.forEach(suburb => {
      let state = states.find(s => s.stateName === suburb.state);
      // If state not on the list yet, creating it
      if(state === undefined) {
        state = {
          stateName: suburb.state,
          suburbs: []
        };
        states.push(state);
      }

      // Adding out suburb to the state
      state.suburbs.push(suburb.suburb);
    });
  }

  function getExposureChannelValue(kind : ExposureChannelKind) {
    if(props.exposureChannels === null) return 0;
    const channel = props.exposureChannels.find(c => c.variable === kind);
    if(channel) return channel.indice;
    return 0;
  }
  
  return (
    <section className="page-section SegmentPage__overview">
      <div className="SegmentPage__overview-container container grid">
        <div className="SegmentPage__overview-column grid__col--span4">
          <h5 className="SegmentPage__title">Key Features</h5>
          <div className="content-block content-block--small content-block--no-line content-block--full-height">
            { props.keyFeatures !== null ? 
              <ul className="SegmentPage__key-features">
                <li className="SegmentPage__key-feature">{props.keyFeatures.key_feature1}</li>
                <li className="SegmentPage__key-feature">{props.keyFeatures.key_feature2}</li>
                <li className="SegmentPage__key-feature">{props.keyFeatures.key_feature3}</li>
                <li className="SegmentPage__key-feature">{props.keyFeatures.key_feature4}</li>
                <li className="SegmentPage__key-feature">{props.keyFeatures.key_feature5}</li>
                <li className="SegmentPage__key-feature">{props.keyFeatures.key_feature6}</li>
              </ul>
            : null }
          </div>
        </div>
        <div className="SegmentPage__overview-column grid__col--span4">
          <h5 className="SegmentPage__title">Top Suburbs</h5>
          <div className="SegmentPage__states content-block content-block--small content-block--no-line content-block--full-height">
            {states.map(s => <StateSuburbs key={s.stateName} state={s} />)}
          </div>
        </div>

        <div className="print__page-break"/>

        { props.exposureChannels !== null ?
        <div className="grid__col--span4">
          <h5 className="SegmentPage__title">Channel Exposure</h5>
          <div className="SegmentPage__exposure-channels grid grid--2col">
            <div className="SegmentPage__exposure-channels-column grid__col--span1">
              <ExposureChannel className="SegmentPage__ExposureChannel" tooltipText="Television" kind={ExposureChannelKind.Television} value={getExposureChannelValue(ExposureChannelKind.Television)} /> 
              <ExposureChannel className="SegmentPage__ExposureChannel" tooltipText="Newspaper" kind={ExposureChannelKind.Newspaper} value={getExposureChannelValue(ExposureChannelKind.Newspaper)} /> 
              <ExposureChannel className="SegmentPage__ExposureChannel" tooltipText="SocialMedia" kind={ExposureChannelKind.SocialMedia} value={getExposureChannelValue(ExposureChannelKind.SocialMedia)} /> 
            </div>
            <div className="SegmentPage__exposure-channels-column grid__col--span1">
              <ExposureChannel className="SegmentPage__ExposureChannel" tooltipText="Internet" kind={ExposureChannelKind.Internet} value={getExposureChannelValue(ExposureChannelKind.Internet)} /> 
              <ExposureChannel className="SegmentPage__ExposureChannel" tooltipText="Outdoor" kind={ExposureChannelKind.Outdoor} value={getExposureChannelValue(ExposureChannelKind.Outdoor)} /> 
              <ExposureChannel className="SegmentPage__ExposureChannel" tooltipText="Radio" kind={ExposureChannelKind.Radio} value={getExposureChannelValue(ExposureChannelKind.Radio)} /> 
            </div>
          </div>
        </div> : null}
      </div>
    </section>
  )
}
