import { exportPdf } from '../../Utils';

import {ReactComponent as ShareIcon} from '../../images/feather-icons/share.svg';
import {ReactComponent as PrinterIcon} from '../../images/feather-icons/printer.svg';

import './ActionsBar.css';

export default function ActionsBar() {
  return (
    <div className="ActionsBar">
      <button className="ActionsBar__action" onClick={() => exportPdf()}>
        <ShareIcon className="ActionsBar__action-icon" />
        Export
      </button>
      <button className="ActionsBar__action" onClick={() => window.print()}>
        <PrinterIcon className="ActionsBar__action-icon" />
        Print
      </button>
    </div>
  );
};
