import React from 'react';

import ITopicVariable from '../../interfaces/ITopicVariable';
import VariableWidgetVariants from '../../enums/VariableWidgetVariants';

import './VariableWidget.css';

import pieIcon from '../../images/pie-icon.svg';
import barChartIcon from '../../images/bar-chart-icon.svg';

interface IVariableWidgetProps {
  data: ITopicVariable,
  variant?: VariableWidgetVariants,
  annotation?: string,
  onPress?: React.MouseEventHandler
  modifier?: string
}

export default function VariableWidget(props : IVariableWidgetProps) {
  return (
    <div className={"content-block VariableWidget" + (props.modifier !== undefined ? ' VariableWidget--' + props.modifier : '') } onClick={props.onPress}>
      <h1 className="VariableWidget__title">{props.data.topic}</h1>
      <div className={"VariableWidget__value" + ('variant' in props ? ' VariableWidget__value--' + props.variant : '')}>
        {props.data.variable}
        {'annotation' in props ? <span className="VariableWidget__annotation">{props.annotation}</span> : null}
      </div>
      <div className="VariableWidget__stats">
        <div className="VariableWidget__stat">
          <img className="VariableWidget__stat-icon" alt="Mean" src={pieIcon} />
          {props.data.mean}%
        </div>

        <div className="VariableWidget__stats-separator"></div>

        <div className="VariableWidget__stat">
          <img className="VariableWidget__stat-icon" alt="Index" src={barChartIcon} />
          {props.data.indice}
        </div>
      </div>
    </div>
  );
}
