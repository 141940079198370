import React, {
  useState,
} from 'react';

import './Accordion.css';

interface IAccordionProps {
  children: React.ReactNode
};

export default function Accordion(props: IAccordionProps) {
  const [activeItem, setActiveItem] = useState<number>(0);

  return (
    <ul className="accordion">
    {React.Children.map(
      props.children,
      (child, idx) => {
        if(!React.isValidElement(child)) return null;
        return React.cloneElement(child, {
          active: idx === activeItem,
          onPress: () => setActiveItem(idx)
        });
      } 
    )}
    </ul>
  );
}
