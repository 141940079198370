import { useState, useEffect } from 'react';

import './DataSelector.css';

import IVariableMeta from '../../interfaces/IVariableMeta';

interface IDataSelectorProps {
  variables: IVariableMeta[],
  selectedCategory: string,
  selectedTopic: string,
  selectedVariable: number,
  activeVariables?: number[],
  showTopics?: boolean,
  showVariables?: boolean,
  onCategorySelected?: (category: string) => void,
  onTopicSelected?: (topic: string) => void,
  onVariableSelected?: (variable: number) => void,
}

export default function DataSelector(props : IDataSelectorProps) {
  const [currentCategory, setCurrentCategory] = useState<string>(props.selectedCategory);
  const [currentTopic, setCurrentTopic] = useState<string>(props.selectedTopic);
  const [currentVariable, setCurrentVariable] = useState<number>(props.selectedVariable);

  useEffect(() => setCurrentCategory(props.selectedCategory), [props.selectedCategory]);
  useEffect(() => setCurrentTopic(props.selectedTopic), [props.selectedTopic]);
  useEffect(() => setCurrentVariable(props.selectedVariable), [props.selectedVariable]);

  const categories: string[] = [];
  props.variables.forEach(v => {
    if(!categories.includes(v.category)) categories.push(v.category);
  });

  function selectCategory(category: string) {
    if(category === currentCategory) {
      setCurrentCategory('');
    } else {
      setCurrentCategory(category);
    }
    if(props.onCategorySelected !== undefined) props.onCategorySelected(category);
  }

  function selectTopic(topic: string) {
    setCurrentTopic(topic);
    if(props.onTopicSelected !== undefined) props.onTopicSelected(topic);
  }

  function selectVariable(variable: number) {
    setCurrentVariable(variable);
    if(props.onVariableSelected !== undefined) props.onVariableSelected(variable);
  }

  function getTopicsForCategory(category: string) {
    const renderedTopics: string[] = [];
    return props.variables.filter(v => v.category === category).map((v, idx) => {
      if(renderedTopics.includes(v.topic)) return null;
      renderedTopics.push(v.topic);
      return <li key={idx} onClick={() => selectTopic(v.topic)} className={"DataSelector__topic" + (v.topic === currentTopic ? ' DataSelector__topic--active' : '')}>
        <div className="DataSelector__topic-label">{v.topic}</div>
        {props.showVariables !== undefined && props.showVariables === true ?
        <ul className="DataSelector__variables">
          {v.topic === currentTopic ? getVariablesForTopic(v.topic) : null}
        </ul> : null}
      </li>;
    });
  }

  function getVariablesForTopic(topic: string) {
    return props.variables.filter(v => v.topic === topic).map((v, idx) => {
      return <li key={idx} onClick={() => selectVariable(v.id)} className={"DataSelector__var" + (props.activeVariables !== undefined && props.activeVariables.indexOf(v.id) !== -1 ? ' DataSelector__var--active' : '') + (props.activeVariables === undefined && v.id === currentVariable ? ' DataSelector__var--active' : '')}>
        {v.variable}
      </li>
    });
  }

  return (
    <div className="DataSelector">
      <ul className="DataSelector__categories">
      {categories.map((c, idx) => 
        <li key={idx} className={"DataSelector__category" + (c === currentCategory ? ' DataSelector__category--active' : '')}>
          <span className="DataSelector__category-label" onClick={() => selectCategory(c)}>{c}</span>
          {props.showTopics === true ?
          <ul className="DataSelector__topics">
          {c === currentCategory ? getTopicsForCategory(c) : null}
          </ul> : null}
        </li>
      )}
      </ul>
    </div>
  )
}

DataSelector.defaultProps = {
  showTopics: true
};
