import ISortOption from '../interfaces/ISortOption';

const segmentSortOptions: ISortOption[] = [
  { reverse: false, key: 'age',                 name: 'Age',                label: 'Young - Old' },
  { reverse: false, key: 'children_at_addr',    name: 'Children at Address',label: 'Unlikely - Likely' },
  { reverse: false, key: 'household_income',    name: 'Household Income',   label: 'High - Low' },
  { reverse: false, key: 'house_price',         name: 'House Price',        label: 'High - Low' },
  { reverse: false, key: 'remoteness',          name: 'Remoteness',         label: 'Urban - Remote' },
  { reverse: false, key: 'house_type',          name: 'House Type',         label: 'Flats - Houses' },
  { reverse: false, key: 'length_of_residency', name: 'Length of Residency',label: 'Short - Long' },
  { reverse: false, key: 'birthplace',          name: 'Birthplace',         label: 'Australia - Overseas' },
];

export default segmentSortOptions;
