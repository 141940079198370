import { IVariableWidgetConfig } from '../interfaces/IVariableWidget';
import VariableWidgetVariants from '../enums/VariableWidgetVariants';

const whoWeAreWidgetsConfig: IVariableWidgetConfig[] = [
  {
    variableName: 'Age',
    variant: VariableWidgetVariants.Large,
  },
  {
    variableName: 'Weekly_Household_Income',
    variant: VariableWidgetVariants.Medium,
    //annotation: 'per week'
  },
  {
    variableName: 'Household_Composition',
    variant: VariableWidgetVariants.Medium,
  },
  {
    variableName: 'Property-Value_Estimates',
    variant: VariableWidgetVariants.Default
  },
  {
    variableName: 'Property_Tenure',
    variant: VariableWidgetVariants.Large,
  },
  {
    variableName: 'Remoteness',
    variant: VariableWidgetVariants.Medium,
  },
  {
    variableName: 'Employment_Status',
    variant: VariableWidgetVariants.Medium,
  },
  {
    variableName: 'Mortgage_Repayment',
    variant: VariableWidgetVariants.Medium,
  },
];

export { whoWeAreWidgetsConfig };
