import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import { sortByKey } from '../../Utils';
import { fontFamily } from '../../Options';

import ChartColors from '../../constants/ChartColors';
import IVariableCompareData from '../../interfaces/IVariableCompareData';
import IDataSelectorProps from '../../interfaces/IDataSelectorProps';
import ILooseObject from '../../interfaces/ILooseObject';
import SegmentKind from '../../enums/SegmentKind';
import VariableSortOptions from '../../enums/VariableSortOptions';

import DataSelector from '../../components/DataSelector/DataSelector';
import ActionsBar from '../../components/ActionsBar/ActionsBar';

import {ReactComponent as SearchIcon} from '../../images/feather-icons/search.svg';

import './VariableCompare.css';

interface IVariableCompareProps extends IDataSelectorProps {
  data: IVariableCompareData
}

const barOptions = {
  maintainAspectRatio: false,
  base: 100,
  scales: {
    y: {
      max: 300,
      ticks: {
        color: '#111',
        font: {
          size: 16,
          weight: 'bold',
          family: "'Roboto', 'Helvetica Neue', sans-serif"
        },
        stepSize: 100
      },
      grid: {
        display: false,
        borderWidth: 0
      }
    },
    x: {
      ticks: {
        display: false
      },
      grid: {
        display: false,
        borderWidth: 0
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
  }
};

export default function VariableCompare(props: IVariableCompareProps) {
  const [segmentKind, setSegmentKind] = useState<SegmentKind>(SegmentKind.Group);
  const [sorting, setSorting] = useState<VariableSortOptions>(VariableSortOptions.Default);

  const [filterString, setFilterString] = useState<string>('');

  const nonDataKeys = ['id', 'category', 'topic', 'variable', 'source'];
  let dataLabels = Object.keys(props.data).filter(k => !nonDataKeys.includes(k));
  let chartColors = ChartColors;

  if(segmentKind === SegmentKind.Group) {
    dataLabels = dataLabels.filter(l => l.length === 1);
  } else {
    dataLabels = dataLabels.filter(l => l.length > 1);
  }

  let dataEntries = dataLabels.map(l => {
    return {
      label: l,
      value: props.data[l as keyof IVariableCompareData]
    }
  });

  if(sorting === VariableSortOptions.Rank) {
    dataEntries = dataEntries.sort((a, b) => sortByKey(a, b, 'value'));
  }

  if(segmentKind === SegmentKind.Type) {
    // We want to use same color for same letter types
    const colorsMap: ILooseObject = {};
    chartColors = [];
    let colorIndex = 0;
    dataEntries.forEach(e => {
      const letter = e.label[0];
      if(!(letter in colorsMap)) {
        colorsMap[letter] = ChartColors[colorIndex % ChartColors.length];
        colorIndex++;
      }

      chartColors.push(colorsMap[letter]);
    });
  }

  const data = function(canvas : HTMLCanvasElement) {
    return {
      labels: dataEntries.map(e => e.label),
      datasets: [
        {
          label: props.data.topic,
          data: dataEntries.map(e => e.value),
          backgroundColor: chartColors
        }
      ]
    };
  } 

  const plugins = [
    {
      id: 'expn_var_compare_bg_n_labels',
      beforeDraw: function(chart: Chart) {
        const ctx = chart.ctx;
        const rectStart = chart.chartArea.top + chart.chartArea.height * (2/3);
        const rectHeight = chart.chartArea.top + chart.chartArea.height * (1/3);
        ctx.rect(chart.chartArea.left, rectStart, chart.chartArea.width, rectHeight);
        ctx.fillStyle = '#f0f0f0';
        ctx.fill();
      },
      afterDraw: function(chart: Chart, args: {}, options: any) {
        // Showing letters on Groups only
        const ctx = chart.ctx;

        const fontSize = '24px'
        const fontWeight = 'bold';
        //ctx.fillStyle = '#26478D';
        ctx.textAlign = 'center';

        //const canvasWidth = ctx.canvas.width;
        chart.data.datasets.forEach(function (dataset: any, i : number) {
          var meta = chart.getDatasetMeta(i);
          meta.data.forEach(function(bar: any, index: number) {
            const data: number = dataset.data[index];                            
            if(chart === undefined || chart.data === undefined || chart.data.labels === undefined) return;
            const label: string = chart.data.labels[index] as string;
            if(label.length > 1) return;

            let fillColor = '#26478D';
            let labelPos = bar.y - 10;
            let valuePos = bar.y - (24 + 10);
            if(data <= 100) {
              labelPos = bar.y + 16 + 10;
              valuePos = bar.y + 16 + 24 + 10;
            }
            if(data > 280) {
              fillColor = 'white';
              if(data < 300) {
                labelPos = bar.y + 24;
                valuePos = bar.y + 24 + 24;
              } else {
                labelPos = chart.chartArea.top + 24;
                valuePos = chart.chartArea.top + 24 + 24;
              }
            } else if(data < 22) {
              fillColor = 'white';
              labelPos = bar.y - 7 - 20;
              valuePos = bar.y - 7;
            }

            ctx.fillStyle = fillColor;
            ctx.font = `${fontWeight} ${fontSize} ${fontFamily}`;
            ctx.fillText(label, bar.x, labelPos);
            ctx.font = `${fontWeight} 16px ${fontFamily}`;
            ctx.fillText(data.toString(), bar.x, valuePos);
          });
        });
      }
    } 
  ];

  function getDataSelectorVariables() {
    if(props.dataSelectorVariables === undefined) return [];

    if(filterString !== '') {
      const searchString = filterString.toLowerCase();
      const filteredVariables = props.dataSelectorVariables.filter(v => v.topic.toLowerCase().indexOf(searchString) !== -1 || v.category.toLowerCase().indexOf(searchString) !== -1);
      return filteredVariables;
    }

    return props.dataSelectorVariables;
  }

  return (
    <div className="VariableCompare content-block chart-block">
      <div className="chart-block__headers grid">
        <div className="chart-block__chart-header grid__col--span9">
          <h5>{props.data.topic} | {props.data.variable}</h5>
          <ActionsBar />
        </div>
        <div className="chart-block__filter-header grid__col--span3">
          <h5>Chart Options</h5>
        </div>
      </div>
      <div className="chart-block__content grid">
        <div className="chart-block__chart-content grid__col--span9">
          <Bar className="chart-block__chart" data={data} options={barOptions} plugins={plugins} />
        </div>
        <div className="chart-block__filter-content grid__col--span3">
          <div className="side-options__label">Select</div>
          <div className="btn-group">
            <button className={"btn" + (segmentKind === SegmentKind.Group ? ' btn--purple' : '')} onClick={() => setSegmentKind(SegmentKind.Group)}>Groups</button> 
            <button className={"btn" + (segmentKind === SegmentKind.Type ? ' btn--purple' : '')} onClick={() => setSegmentKind(SegmentKind.Type)}>Types</button> 
          </div>
    
          <div className="side-options__label">Order</div>
          <div className="btn-group">
            <button className={"btn" + (sorting === VariableSortOptions.Default ? ' btn--purple' : '')} onClick={() => setSorting(VariableSortOptions.Default)}>Default</button> 
            <button className={"btn" + (sorting === VariableSortOptions.Rank? ' btn--purple' : '')} onClick={() => setSorting(VariableSortOptions.Rank)}>Rank</button> 
          </div>

          { props.showDataSelector !== undefined && props.dataSelectorVariables !== undefined ?
          <div>
            <div className="side-options__label">Variables</div>
            <div className="VariableCompare__DataSelector-search search-field input-group">
              <input className="search-field__input input input--small" name="filter-string" placeholder="Search..." value={filterString} onChange={(e) => setFilterString(e.target.value)} />
              <button className="search-field__btn" type="submit"><SearchIcon /></button>
            </div>
            <div className="VariableCompare__DataSelector-container condensed-scroll">
              <DataSelector variables={getDataSelectorVariables()} selectedCategory={props.data.category} selectedTopic={props.data.topic} selectedVariable={props.data.id} onTopicSelected={props.onTopicSelected} onVariableSelected={props.onVariableSelected} showVariables={props.showDataSelectorVariables} />
            </div>
          </div>
          : null}
          </div>
      </div>
    </div>
  )
}
