import React from 'react';

import PageContent from '../components/PageContent/PageContent';

export default function AboutPage() {
  return (
    <div className="AboutPage page">
      <section className="container content-block">
        <PageContent slug="about-us" />
      </section>
    </div>
  );
}
