import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

function Footer() {
  return (
    <footer className="Footer">
      <div className="container">
        <span className="Footer__part Footer__copyright">Copyright &copy; Experian 2021</span>
        <Link className="Footer__part Footer__link" to="/terms">Terms & Conditions</Link>
        <Link className="Footer__part Footer__link" to="/privacy">Privacy Statement</Link>
        <span className="Footer__part Footer__version">Software Version 1.2.3</span>
      </div>
    </footer>
  );
}

export default Footer;

