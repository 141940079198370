import { 
  useState,
  useEffect
} from 'react';
import { useHistory } from 'react-router-dom';

import Options from '../../Options';

import SegmentCard from '../../components/SegmentCard/SegmentCard';
import PageContent from '../../components/PageContent/PageContent';
import Accordion from '../../elements/Accordion/Accordion';
import AccordionItem from '../../elements/Accordion/AccordionItem';

import ISavedSearch from '../../interfaces/ISavedSearch';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ILooseObject from '../../interfaces/ILooseObject';

import './DashboardPage.css';

interface IDashboardPageProps {
  segments: ISegmentBasic[],
  supergroups: ISegmentBasic[]
};

export default function DashboardPage(props: IDashboardPageProps) {
  const history = useHistory();
  const [savedSearches, setSavedSearches] = useState<ISavedSearch[] | null>(null);
  const [recentlyViewedSegments, setRecentlyViewedSegments] = useState<string[] | null>(null);

  useEffect(() => {
    if(savedSearches !== null) return;
    (async () => {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/saved-searches?timestamp=${Date.now()}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data: ISavedSearch[] = await response.json();
        setSavedSearches(data);
      }
    })();
  }, [savedSearches]);

  useEffect(() => {
    if(recentlyViewedSegments !== null) return;
    (async () => {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/segments/recently-viewed?timestamp=${Date.now()}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data: string[] = await response.json();
        setRecentlyViewedSegments(data);
      }
    })();
  }, [recentlyViewedSegments]);

  function getSegmentsSubset(segments: ISegmentBasic[], codes: string[]) {
    if(segments.length === 0) return [];
    const map: ILooseObject = {};
    codes.forEach(code => map[code] = null);
    segments.forEach(segment => {
      if(codes.indexOf(segment.code) === -1) return;
      map[segment.code] = segment;
    });
    const subset = Object.values(map);
    return subset;
  }

  function openSearch(s: ISavedSearch) {
    const path = `/identify/search?id=${s.id}&kind=${s.kind}&threshold=${s.threshold}&variables=${s.variables.join()}`;
    history.push(path);
  }

  return (
    <div className="DashboardPage page">
      <div className="container grid">
        <div className="DashboardPage__content grid__col--span8">
          <PageContent slug="dashboard" />

          <h5 className="DashboardPage__section-title">Recently Viewed Groups & Types</h5>
          <div className="DashboardPage__segments-container">
            {recentlyViewedSegments !== null ? getSegmentsSubset(props.segments, recentlyViewedSegments).slice(0, 6).map(segment => <SegmentCard className="DashboardPage__segment-card" key={segment.id} segment={segment} />) : null}
          </div>

          <h5 className="DashboardPage__section-title">Recently Created Supergroups</h5>
          <div className="DashboardPage__segments-container">
            {props.supergroups.slice(0, 6).map(segment => <SegmentCard className="DashboardPage__segment-card" key={segment.id} segment={segment} />)}
          </div>
        </div>
        <div className="grid__col--span4 content-block">
          <h2>Recent Audience Searches</h2> 
          <Accordion>
          {savedSearches !== null ? savedSearches.slice(0, 5).map(s => {
            return (
              <AccordionItem key={s.id} title={s.name} extraBtnText="View Search" onExtraBtnPress={() => openSearch(s)}>
                <div className="DashboardPage__search-segments">
                {props.segments.filter(i => s.segments.indexOf(i.code) !== -1).map(segment => <SegmentCard className="DashboardPage__search-segment" variant="small" key={segment.id} segment={segment} />)}
                </div>
              </AccordionItem>
            )
          }) : null}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
