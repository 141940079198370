import React, { useState } from 'react';

import Options from '../../Options';

import IUser from '../../interfaces/IUser';
import IRestError from '../../interfaces/IRestError';
import ILooseObject from '../../interfaces/ILooseObject';

import './AuthPage.css';

interface ILoginOptions extends ILooseObject {
  username: string,
  password: string
};

const defaultLoginOptions = {
  username: '',
  password: ''
};

interface IJWTAuthResponse {
  token: string,
  user_email: string,
  user_nicename: string,
  user_display_name: string
};

interface IWPAvatars {
  24: string,
  48: string,
  96: string
};

interface IWPUser {
  avatar_urls: IWPAvatars
};

interface IAuthPageProps {
  onUserLogged: (user: IUser) => void
};

export default function AuthPage(props: IAuthPageProps) {
  const [error, setError] = useState<string>('');
  const [loginOptions, setLoginOptions] = useState<ILoginOptions>(Object.assign(defaultLoginOptions));

  function loginOptionChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const newLoginOptions: ILoginOptions = {...loginOptions};
    newLoginOptions[event.target.name] = event.target.value;
    setLoginOptions(newLoginOptions);
  }

  function loginFormSubmitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    (async function() {
      const response = await fetch(`${Options.ApiBase}/wp-json/jwt-auth/v1/token`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          username: loginOptions.username,
          password: loginOptions.password
        })
      });
      if(response.ok) {
        const data : IJWTAuthResponse = await response.json();
        const user: IUser = {
          display_name: data.user_display_name,
          avatar_url: await getAvatar(data.token),
          token: data.token
        };
        props.onUserLogged(user);
      } else {
        const error: IRestError = await response.json();
        setError(error.message);
      }
    })();
  }

  async function getAvatar(token: string) {
    const headers: HeadersInit = new Headers();
    headers.set('Authorization', 'Bearer ' + token);
    const response = await fetch(`${Options.ApiBase}/wp-json/wp/v2/users/me`, { headers });
    if(response.ok) {
      const data: IWPUser = await response.json();
      console.log(data);
      return data.avatar_urls[96];
    }
    return '';
  }
  
  return (
    <div className="AuthPage page">
      <div className="AuthPage__container container">
        <div className="AuthPage__image"></div>
        <div className="AuthPage__form-container content-block">
          <h1 className="AuthPage__title">Login</h1>
          {error !== '' ?
          <p className="AuthPage__error-msg">{error}</p>
          : null}
          <form className="AuthPage__form" onSubmit={loginFormSubmitHandler}>
            <div className="side-options__label">User name</div>
            <div className="input-group">
              <input className="input AuthPage__username-input" type="text" name="username" placeholder="username" value={loginOptions.username} onChange={loginOptionChangeHandler} />
            </div>
            <div className="side-options__label">Password</div>
            <div className="input-group">
              <input className="input AuthPage__password-input" type="password" name="password" placeholder="password" value={loginOptions.password} onChange={loginOptionChangeHandler} />
            </div>
            <button className="AuthPage__btn btn btn--purple" type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}
