import { useState, useEffect } from 'react';

import Options from '../../Options';

import ISegmentPhoto from '../../interfaces/ISegmentPhoto';

import './PhotosGrid.css';

interface IPhotosGridProps {
  segments: string[],
  onPhotoPress?: (photo: ISegmentPhoto) => void
};

export default function PhotosGrid(props: IPhotosGridProps) {
  const [photos, setPhotos] = useState<ISegmentPhoto[]>([]);

  useEffect(() => {
    const promises = props.segments.map((segment) => {
      const isSuperGroup = segment[0] === '*';
      return new Promise<ISegmentPhoto[]>(async (resolve) => {
        const response = await fetch(`${Options.ApiBase}/wp-json/experian/${isSuperGroup ? 'supergroups' : 'segments'}/${segment}/photos`, { headers: Options.ApiHeaders });
        if(response.ok) {
          const data = await response.json();
          resolve(data);
        } else {
          resolve([]);
        }
      });
    }); 
    Promise.all(promises).then((photosArray: ISegmentPhoto[][]) => {
      let newPhotos: ISegmentPhoto[] = [];
      photosArray.forEach((ph) => newPhotos = [...newPhotos, ...ph]);
      setPhotos(newPhotos);
    });
  }, [props.segments]);

  function clickHandler(event: React.MouseEvent, photo: ISegmentPhoto) {
    if(props.onPhotoPress === undefined) return;
    event.preventDefault();
    props.onPhotoPress(photo);
  }

  return (
    <div className="PhotosGrid">
      {photos !== null ?
        photos.map(p => <a key={p.id} className="PhotosGrid__photo" onClick={(e) => clickHandler(e, p)} href={p.full} target="_blank" rel="noreferrer"><img src={p.thumbnail} alt={p.alt} /></a>)
      : null}
    </div>
  )
}
