import React from 'react';

import PageContent from '../components/PageContent/PageContent';

export default function HelpPage() {
  return (
    <div className="HelpPage page">
      <section className="container content-block">
        <PageContent slug="help" />
      </section>
    </div>
  );
}
