import Tooltip from '../../elements/Tooltip/Tooltip';


import ExposureChannelKind from '../../enums/ExposureChannelKind';

import {ReactComponent as MonitorIcon} from '../../images/feather-icons/monitor.svg';
import {ReactComponent as SearchIcon} from '../../images/feather-icons/search.svg';
import {ReactComponent as FileTextIcon} from '../../images/feather-icons/file-text.svg';
import {ReactComponent as SunIcon} from '../../images/feather-icons/sun.svg';
import {ReactComponent as FacebookIcon} from '../../images/feather-icons/facebook.svg';
import {ReactComponent as Volume2Icon} from '../../images/feather-icons/volume-2.svg';

import './ExposureChannel.css';

interface IExposureChannelProps {
  className?: string,
  kind: ExposureChannelKind,
  value: number,
  tooltipText: string
}
export default function ExposureChannel(props : IExposureChannelProps) {
  function getChannelIcon() {
    switch(props.kind) {
      case ExposureChannelKind.Television:
        return <MonitorIcon />;
      case ExposureChannelKind.Internet:
        return <SearchIcon />;
      case ExposureChannelKind.Newspaper:
        return <FileTextIcon />;
      case ExposureChannelKind.Outdoor:
        return <SunIcon />;
      case ExposureChannelKind.SocialMedia:
        return <FacebookIcon />;
      case ExposureChannelKind.Radio:
        return <Volume2Icon />;
    }
  }

  return (
    <div className={"ExposureChannel content-block content-block--small content-block--no-line " + props.className}>
      <h2 className="ExposureChannel__value">{props.value}</h2>
      <h1 className="ExposureChannel__kind">{props.kind}</h1>
      <Tooltip className="ExposureChannel__icon" text={props.tooltipText}>{ getChannelIcon() }</Tooltip>
    </div>
  )
}
