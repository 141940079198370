import React from 'react';
import { useHistory } from 'react-router-dom';

import IClassNameProps from '../../interfaces/IClassNameProps';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import './SegmentCard.css';

interface ISegmentCardProps extends IClassNameProps {
  variant?: string,
  segment: ISegmentBasic,
  selected?: boolean,
  onPress?: () => boolean
}

function SegmentCard(props: ISegmentCardProps) {
  const history = useHistory();
  let titleClass : string = 'heading--h1';
  if('variant' in props && props.variant === 'small') titleClass = 'heading--h2';

  function clickHandler() {
    const handlerResult = props.onPress !== undefined ? props.onPress() : true;
    if(handlerResult !== false) history.push('/segments/' + props.segment.code);
  }

  function getSegmentType(name: string) {
    if(name.length === 1) return 'Group';
    if(name[0] === '*') return 'Super Group';
    return 'Type';
  }

  return (
    <div onClick={() => clickHandler()} className={props.className + " SegmentCard" + ('variant' in props ? (' SegmentCard--' + props.variant) : ' SegmentCard--large') + (props.selected !== undefined && props.selected ? ' SegmentCard--selected' : '')} style={{'--hover-color' : props.segment.primary_hex} as React.CSSProperties}>
      <div className="SegmentCard__line" style={{ backgroundColor: props.segment.primary_hex }}></div>
    
      <h1 className={'SegmentCard__code ' + titleClass}>
        {props.segment.code}
        <span className="SegmentCard__household">{props.segment.household.toFixed(2)}%</span>
      </h1>

      <div className="SegmentCard__type-label">{getSegmentType(props.segment.code)}</div>
      <div className="SegmentCard__name">{props.segment.name}</div>
    </div>
  );
}

export default SegmentCard;
