import { StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: '8 15',
    fontFamily: 'Roboto',

    fontSize: 8,
  },

  containerGrey: {
    backgroundColor: '#f5f5f5',
    padding: 17
  },

  header: {
    marginBottom: 8
  },

  header__logo: {
    width: 76,
    height: 25
  },

  titleBar: {
    height: 21,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#26478D',
    color: 'white',
    justifyContent: 'space-between',
    padding: '0 16',
  },

  titleBar__code: {
    fontWeight: 700,
    marginRight: 8
  },

  titleBar__name: {
    marginRight: 'auto'
  },

  titleBar__household: {},

  hero: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 175,
    backgroundColor: '#972D80',
    color: 'white',
    padding: '21 16'
  },

  heroBackground: {
    position: 'absolute',
    width: 566,
    height: 175,
    objectFit: 'cover'
  },

  heroMain: {
    justifyContent: 'flex-end'
  },

  heroTitle: {
    fontSize: 25,
    fontWeight: 300
  },

  heroDescription: {},

  heroStats: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  heroStat: {
    marginTop: 4
  },

  heroStatValue: {
    fontWeight: 700
  },

  heroStatLabel: {
    fontSize: 6,
    opacity: 0.7
  },

  propsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  propsColumn: {
    width: '100%',
    marginRight: 7
  },

  propsColumnLast: {
    marginRight: 0
  },

  propsTitle: {
    fontWeight: 700,
    marginBottom: 12
  },

  propsContent: {
    backgroundColor: 'white',
    height: 200 - 18 * 2,
    padding: '18 15'
  },

  propsContentKeyFeatures: {
    justifyContent: 'space-between'
  },

  keyFeature: {
    position: 'relative',
    flexDirection: 'row',
    paddingLeft: 10
  },

  keyFeatureBullet: {
    width: 4, height: 4,
    backgroundColor: '#26478D',
    borderRadius: 50,

    position: 'absolute',
    left: 0, top: '50%',
    marginTop: -2
  },

  keyFeatureText: {
    color: '#666'
  },

  propsContentStates: {
    justifyContent: 'space-between'
  },

  state: {
    flexDirection: 'row',
    borderBottom: '0.5 solid #ccc',
    padding: '8 0'
  },

  stateFirst: {
    paddingTop: 0
  },

  stateLast: {
    borderBottom: '',
    paddingBottom: 0
  },

  stateName: {
    fontSize: 10,
    color: '#111',
    width: 35
  },

  stateSuburbs: {
    fontSize: 6,
    lineHeight: 1.5,
    color: '#666',
    marginRight: 'auto',
    textTransform: 'capitalize'
  },

  stateImage: {},

  exposureChannels: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },

  exposureChannel: {
    width: 84, height: 51.5,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginBottom: 5
  },

  exposureChannelValue: {
    fontSize: 17,
    fontWeight: 300,
    color: '#406EB3'
  },

  exposureChannelLabel: {
    fontSize: 7,
    color: '#666'
  },

  exposureChannelIcon: {
    position: 'absolute',
    width: 10, height: 10,
    top: 4, left: 4
  },

  sectionTitle: {
    fontWeight: 700,
    margin: '21 0 12'
  },

  whoWeAreWidgets: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },

  widget: {
    width: 128, height: 96,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    marginBottom: 6
  },

  widgetTopLine: {
    width: '100%',
    height: 3,
    marginBottom: 6,
    objectFit: 'cover'
  },

  widgetLabel: {
    fontSize: 6,
    lineHeight: 1.5,
    height: 18,
    color: '#999',
  },

  widgetValue: {
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: 15,
    fontWeight: 300,
    color: '#26478D',
    textAlign: 'center',

    maxWidth: '90%'
  },

  widgetValueSmall: {
    fontSize: 9,
    fontWeight: 400,
    color: '#111',
  },

  widgetValueLarge: {
    fontSize: 21,
    fontWeight: 300,
    color: '#26478D'
  },

  widgetStats: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    fontSize: 6,
    color: '#aaa'
  },

  widgetIcon: {
    width: 10, height: 10,
  },

  widgetMean: {
    borderRight: '0.5 solid #aaa',
    margin: '0 4 0 2',
    paddingRight: 7,
  },

  widgetIndex: {
    marginLeft: 2
  },

  descriptionTitle: {
    fontWeight: 700,
    margin: '21 0 12'
  },

  descriptionText: {
    color: '#111'
  },

  segments: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  segment: {
    position: 'relative',
    backgroundColor: 'white',
    width: 128, height: 74,
    marginBottom: 6
  },

  segmentContent: {
    width: '100%', height: '100%',
    padding: '0 10',
  },

  segmentTopLine: {
    width: '100%', height: 3,
    marginBottom: 6
  },

  segmentCode: {
    fontSize: 17,
    fontWeight: 700,
    color: '#111',
    marginBottom: 'auto',
  },

  segmentKind: {
    fontSize: 8,
    fontWeight: 700,
    color: '#999',
    textTransform: 'uppercase'
  },

  segmentName: {
    fontSize: 9,
    fontWeight: 700,
    color: '#111',
    marginBottom: 6
  },

  segmentHousehold: {
    position: 'absolute',
    top: 10, right: 8,
    fontSize: 8,
    color: '#999'
  }
});

export { styles };
