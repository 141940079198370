import React from 'react';
import {ReactComponent as XIcon} from '../../images/modal-icon-x.svg';

import './ModalWindow.css';

interface IModalWindowProps {
  active: boolean
  children: React.ReactNode,
  title?: string,
  onCloseBtnPress?: () => void
};

export default function ModalWindow(props: IModalWindowProps) {
  return (
    <div className={"ModalWindow" + (props.active ? ' ModalWindow--active': '')}>
      <div className="ModalWindow__modal container content-block">
        {props.title !== undefined && props.title !== '' ?
        <h2 className="ModalWindow__title">{props.title}</h2>
        : null}
        <button className="ModalWindow__close-btn" onClick={() => props.onCloseBtnPress !== undefined ? props.onCloseBtnPress() : undefined}><XIcon/></button>
        <div className="ModalWindow__content condensed-scroll">{props.children}</div>
      </div>
    </div>
  );
}
