import { useParams } from 'react-router-dom';

import { 
  getSegmentByCode
} from '../../Utils';

import Page404 from '../Page404/Page404';
/* Components */
import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';
import SegmentPageTitleBar from '../../components/SegmentPageTitleBar/SegmentPageTitleBar';
import ActionsBar from '../../components/ActionsBar/ActionsBar';
import PhotosGrid from '../../components/PhotosGrid/PhotosGrid';

import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ISegmentBasic from '../../interfaces/ISegmentBasic';

import './SegmentPhotosPage.css'

export default function SegmentPhotosPage(props: ISegmentPageProps) {
  const params : ISegmentPageParams = useParams();
  const isSuperGroup = params.code[0] === '*';
  const segmentOverview : ISegmentBasic | undefined = isSuperGroup ? getSegmentByCode(params.code, props.supergroups) : getSegmentByCode(params.code, props.segments);
  //const [photos, setPhotos] = useState<ISegmentPhoto[] | null>(null);

  //useEffect(() => {
  //  if(segmentOverview === undefined) return;
  //  (async function() {
  //    const response = await fetch(`${Options.ApiBase}/wp-json/experian/${isSuperGroup ? 'supergroups' : 'segments'}/${params.code}/photos`, { headers: Options.ApiHeaders });
  //    if(response.ok) {
  //      const data = await response.json();
  //      setPhotos(data);
  //    }
  //  })();
  //}, [params.code, segmentOverview]);
  
  if(props.segments.length === 0) return (
    <div className="SegmentPhotosPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <strong>Loading...</strong>
    </div>
  );

  // If segment is not in the list it probably doesn't exist
  if(segmentOverview === undefined) return <Page404 />;

  return (
    <div className="SegmentPhotosPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />

      <section className="SegmentPhotosPage__section container page-section page-section--top-space">
        <div className="SegmentPhotosPage__content content-block">
          <div className="SegmentPhotosPage__top-bar">
            <ActionsBar />
          </div>
          <PhotosGrid segments={[params.code]}/>
          {/*<div className="SegmentPhotosPage__photos">
            {photos !== null ?
              photos.map(p => <a key={p.id} className="SegmentPhotosPage__photo" href={p.full}><img src={p.thumbnail} alt={p.alt} /></a>)
            : null}
          </div>*/}
        </div>
      </section>
    </div>
  );
}
