import React, {
  useState, 
  useEffect,
  useCallback
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { Location } from 'history';

import './styles/colors.css';
import './styles/layout.css';
import './styles/typography.css';
import './styles/grid-system.css';

import './styles/elements/buttons.css';
import './styles/elements/inputs.css';
import './styles/elements/search-field.css';
import './styles/elements/tags.css';
import './styles/elements/range.css';
import './styles/elements/lists.css';
import './styles/elements/side-options.css';
import './styles/elements/buttons.css';
import './styles/elements/chart-block.css';
import './styles/elements/scroll.css';

import './App.css';

import Options from './Options';
import { useInterval } from './Utils';

import IUser from './interfaces/IUser';
import ISegmentBasic from './interfaces/ISegmentBasic';
import ISuperGroupBasic from './interfaces/ISuperGroupBasic';
import IVariableMeta from './interfaces/IVariableMeta';
import SegmentKind from './enums/SegmentKind';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import Page404 from './pages/Page404/Page404';
import AboutPage from './pages/AboutPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import HelpPage from './pages/HelpPage';
import PdfReportPage from './pages/PdfReportPage/PdfReportPage';

import DashboardPage from './pages/DashboardPage/DashboardPage';
import AuthPage from './pages/AuthPage/AuthPage';
import ComparePage from './pages/ComparePage/ComparePage';
import SegmentsPage from './pages/SegmentsPage/SegmentsPage';
import SegmentPage from './pages/SegmentPage/SegmentPage';
import SegmentDataPage from './pages/SegmentDataPage/SegmentDataPage';
import SegmentPhotosPage from './pages/SegmentPhotosPage/SegmentPhotosPage';
import SegmentDescriptionPage from './pages/SegmentDescriptionPage/SegmentDescriptionPage';
import SegmentTreePage from './pages/SegmentTreePage/SegmentTreePage';
import SegmentMapPage from './pages/SegmentMapPage/SegmentMapPage';
import AudienceSearchPage from './pages/AudienceSearchPage/AudienceSearchPage';
import AudienceSearchResultsPage from './pages/AudienceSearchResultsPage/AudienceSearchResultsPage';

function App() {
  const history = useHistory();
  let location : Location = useLocation<Location>();

  const [initialUserCheckDone, setInitialUserCheckDone] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [segments, setSegments] = useState<ISegmentBasic[]>([]);
  const [supergroupsRequested, setSupergroupsRequested] = useState<boolean>(false);
  const [supergroups, setSupergroups] = useState<ISuperGroupBasic[]>([]);
  const [variablesList, setVariablesList] = useState<IVariableMeta[]>([]);

  const setAuthedUser = useCallback(function(user: IUser, saveToLocalStorage = true, redirectToHome = true) {
    setUser(user);
    Options.ApiToken = user.token;
    const headers: HeadersInit = new Headers();
    headers.set('Authorization', 'Bearer ' + user.token);
    Options.ApiHeaders = headers;
    if(saveToLocalStorage) localStorage.setItem('experianUser', JSON.stringify(user));
    if(redirectToHome) history.push('/');
  }, [history]);

  const checkAuth = useCallback(function(user: IUser | null, time: number) {
    if(user === null) return;
    (async () => {
      const response = await fetch(Options.ApiBase + '/wp-json/jwt-auth/v1/token/validate', { method: 'POST', headers: Options.ApiHeaders });
      if(!response.ok) logoutUser();
    })();
  }, []);

  useEffect(() => {
    if(user === null || segments.length > 0) return;
    getSegments();
  }, [user, segments]);

  useEffect(() => {
    if(user === null || supergroupsRequested) return;
    getSupergroups();
    setSupergroupsRequested(true);
  }, [user, supergroups, supergroupsRequested]);

  useEffect(() => {
    if(user === null || variablesList.length > 0) return;
    getVariables();
  }, [user, variablesList]);

  useEffect(() => {
    if(user !== null || initialUserCheckDone === true) return;

    const lsUserJson = localStorage.getItem('experianUser');
    if(lsUserJson !== null) {
      const lsUser = JSON.parse(lsUserJson);
      setAuthedUser(lsUser, false, false);
      checkAuth(lsUser, Date.now()); // making sure that we are still authorised
    } 
    setInitialUserCheckDone(true);
  }, [user, initialUserCheckDone, setAuthedUser, checkAuth]);

  useInterval(() => {
    checkAuth(user, Date.now());
  }, 30000);

  function isSegmentPage() {
    if(['/segments/groups', '/segments/types'].includes(location.pathname)) return false;
    if(location.pathname.indexOf('/segments/') === 0) return true;
    return false;
  }

  async function getSegments() {
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/segments`, { headers: Options.ApiHeaders });
    if(response.ok) {
      let segments : ISegmentBasic[] = await response.json();
      setSegments(segments);
    }
  }

  async function getSupergroups() {
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/supergroups?timestamp=${Date.now()}`, { headers: Options.ApiHeaders });
    if(response.ok) {
      let supergroups : ISuperGroupBasic[] = await response.json();
      setSupergroups(supergroups);
    }
  }

  async function getVariables() {
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/variables`, { headers: Options.ApiHeaders });
    if(response.ok) {
      const vars : IVariableMeta[] = await response.json();
      setVariablesList(vars);
    }
  }

  function isUserLoggedIn(user: IUser | null) {
    return user !== null;
  }


  function logoutUser() {
    setUser(null);
    localStorage.removeItem('experianUser');
    document.location.reload();
  }

  if(initialUserCheckDone === false) return null;

  return (
    <div className={'App' + (isSegmentPage() ? ' App--segment-page' : '')} >
      <Header user={user} onLogout={logoutUser}/>

      <div className="App__content">
        <Switch>
          <Route path="/login">
            <AuthPage onUserLogged={(user) => setAuthedUser(user)} />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/terms">
            <TermsPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPage />
          </Route>
          <ProtectedRoute exact path="/" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <DashboardPage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/help" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <HelpPage />
          </ProtectedRoute>
          <ProtectedRoute exact path="/compare" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <ComparePage variablesList={variablesList} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/identify" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <AudienceSearchPage segments={segments} variablesList={variablesList} />
          </ProtectedRoute>
          <ProtectedRoute path="/identify/search" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <AudienceSearchResultsPage segments={segments} supergroups={supergroups} variablesList={variablesList} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/segments" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <Redirect to="/segments/groups" />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/groups" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentsPage segments={segments} supergroups={supergroups} kind={SegmentKind.Group} onSuperGroupCreated={getSupergroups} onSuperGroupDeleted={getSupergroups} />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/types" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentsPage segments={segments} supergroups={supergroups} kind={SegmentKind.Type} onSuperGroupCreated={getSupergroups} onSuperGroupDeleted={getSupergroups} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/segments/:code" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentPage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/segments/:code/export" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <PdfReportPage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/:code/data" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentDataPage segments={segments} supergroups={supergroups} variablesList={variablesList} />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/:code/photos" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentPhotosPage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/:code/description" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentDescriptionPage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/:code/tree" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentTreePage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <ProtectedRoute path="/segments/:code/map" isAuthenticated={isUserLoggedIn(user)} authPath="/login">
            <SegmentMapPage segments={segments} supergroups={supergroups} />
          </ProtectedRoute>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </div>

      <Footer />
    </div>
  );
}

export default App;
