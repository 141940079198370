import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Options from '../../Options';
import { sortByKey, getSegmentByCode } from '../../Utils';

import segmentsSortOptions from '../../data/segmentsSortOptions';

import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISegmentPhoto from '../../interfaces/ISegmentPhoto';
import SegmentKind from '../../enums/SegmentKind';

import SegmentCard from '../../components/SegmentCard/SegmentCard';
import PhotosGrid from '../../components/PhotosGrid/PhotosGrid';
import SuperGroupCreator from '../../components/SuperGroupCreator/SuperGroupCreator';
import ModalWindow from '../../elements/ModalWindow/ModalWindow';

import {ReactComponent as SearchIcon} from '../../images/feather-icons/search.svg';

import './SegmentsPage.css';

interface IPostCodeSegment {
  code: string,
  size: number
};

interface ISegmentsPageProps {
  segments: ISegmentBasic[],
  supergroups: ISegmentBasic[],
  kind: SegmentKind,
  onSuperGroupCreated: () => void,
  onSuperGroupDeleted: () => void
};

export default function SegmentsPage(props: ISegmentsPageProps) {
  const history = useHistory();
  const [sortKey, setSortKey] = useState<string>('');
  
  const [selectionMode, setSelectionMode] = useState<boolean>(false);
  const [superGroupMode, setSuperGroupMode] = useState<boolean>(false);
  const [superGroupDeleteMode, setSuperGroupDeleteMode] = useState<boolean>(false);
  
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [superGroupImage, setSuperGroupImage] = useState<ISegmentPhoto | null>(null);
  const [imageSelectVisible, setImageSelectVisible] = useState<boolean>(false);

  const [postcode, setPostcode] = useState<string>('');
  const [postcodeSegments, setPostcodeSegments] = useState<IPostCodeSegment[]>([]);

  function changeSuperGroupMode(state: boolean) {
    setSelectionMode(state);
    setSuperGroupMode(state);
    //if(state) history.push('/segments/types');
    if(!state) {
      setSelectedSegments([]);
      setSuperGroupImage(null);       
    }
  }

  function changeSuperGroupDeleteMode(state: boolean) {
    setSelectionMode(state);
    setSuperGroupDeleteMode(state);
    if(state) history.push('/segments/groups');
    if(!state) {
      setSelectedSegments([]);
    }
  }

  function toggleSegmentSelection(segmentCode: string) {
    const newSelectedSegments = [...selectedSegments];
    const idx = selectedSegments.indexOf(segmentCode);
    if(idx === -1) {
      newSelectedSegments.push(segmentCode);
    } else {
      newSelectedSegments.splice(idx, 1);   
    }
    setSelectedSegments(newSelectedSegments);
  }

  function segmentPressHandler(segmentCode: string) {
    if(!selectionMode) return true;
    toggleSegmentSelection(segmentCode);
    return false;
  }

  function superGroupPhotoSelect(photo: ISegmentPhoto) {
    setSuperGroupImage(photo);
    setImageSelectVisible(false);
  }
  
  function superGroupCreatedHandler() {
    props.onSuperGroupCreated();
    changeSuperGroupMode(false);
  }

  function superGroupCreationErrorHanlder(error: string) {
    alert(error);
    changeSuperGroupMode(false);
  }

  async function deleteSuperGroupsHandler() {
    if(!window.confirm('Do you really want to delete these groups?')) return;

    const supergroups: number[] = [];
    selectedSegments.forEach(code => {
      const supergroup = getSegmentByCode(code, props.supergroups);
      if(supergroup !== undefined) supergroups.push(supergroup.id);
    });
    
    const customHeaders = Object.assign(Options.ApiHeaders);
    customHeaders.set('Content-Type', 'application/json');
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/supergroups/`, {
      method: 'DELETE',
      headers: customHeaders,
      body: JSON.stringify({
        supergroups
      })
    });

    if(!response.ok) {
      alert('There was an error during Super Groups deletion.');
    } else {
      props.onSuperGroupDeleted();
    }

    changeSuperGroupDeleteMode(false); 
  }

  async function postCodeSubmitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(postcode === '') {
      setPostcodeSegments([]);
      return;
    }
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/post-codes/${postcode}/${props.kind}`, { headers: Options.ApiHeaders });
    if(response.ok) {
      const data: IPostCodeSegment[] = await response.json();
      setPostcodeSegments(data);
    } else {
      const error = await response.json();
      alert(error.message);
      setPostcodeSegments([]);
    }
  }

  function getFilteredSegments() {
    if(superGroupDeleteMode) return props.supergroups;

    let segments = [...props.segments, ...props.supergroups];
    // filter by kind
    segments = segments.filter(s => s.kind === props.kind);

    // sort by sort key
    // FIXME: need to be able to set direction of sort
    const sortOption = segmentsSortOptions.find((o) => o.name === sortKey);
    if(sortOption !== undefined && !sortOption.reverse) {
      segments = segments.sort((a, b) => sortByKey(a, b, sortKey));
    } else {
      segments = segments.sort((a, b) => sortByKey(b, a, sortKey));
    }

    // Filtering segments by postcode
    if(postcodeSegments.length > 0) {
      const postcodeSegmentCodes = postcodeSegments.map(s => s.code);
      const newSegments: ISegmentBasic[] = [];

      segments.forEach(segment => {
        const idx = postcodeSegmentCodes.indexOf(segment.code);
        if(idx === -1) return;
        newSegments.push({...segment, ...{ household: postcodeSegments[idx].size }});
      });

      segments = newSegments;
      segments = segments.sort((a, b) => sortByKey(a, b, 'household'));
    }
  
    return segments;
  }

  function resetPostcode() {
    setPostcode('');
    setPostcodeSegments([]);
  }

  return (
    <div className="SegmentsPage page">
      <ModalWindow active={imageSelectVisible} onCloseBtnPress={() => setImageSelectVisible(false)} title="Select image for the Super Group">
        {imageSelectVisible ?
          <PhotosGrid segments={selectedSegments} onPhotoPress={superGroupPhotoSelect}/> : null}
      </ModalWindow>
      <div className="container grid">
        <div className="SegmentsPage__content grid__col--span9">
          <h1>Segments - {props.kind === SegmentKind.Group ? 'Groups' : 'Types'}</h1>
          {props.kind === SegmentKind.Group ? 
            <p className="SegmentsPage__description">Learn about a Group by clicking a tile or enter a postcode in the right column.</p>
            : 
            <p className="SegmentsPage__description">Learn about a Type by clicking a tile or enter a postcode in the right column.</p>
          }
          <div className={"SegmentsPage__segments-container" + (props.kind === SegmentKind.Type ? ' SegmentsPage__segments-container--types' : '')}>
            {/*(superGroupDeleteMode ? props.supergroups : [...props.segments, ...props.supergroups]).filter((s) => s.kind === props.kind).sort((a, b) => sortByKey(a, b, sortKey)).map((segment) => {
              return <SegmentCard onPress={() => segmentPressHandler(segment.code)} key={segment.id} segment={segment} className="SegmentsPage__segment-card" variant={props.kind === SegmentKind.Type ? 'small' : 'large'} selected={selectedSegments.indexOf(segment.code) !== -1} />;
            })*/}
            {getFilteredSegments().map((segment) => {
              return <SegmentCard onPress={() => segmentPressHandler(segment.code)} key={segment.id} segment={segment} className="SegmentsPage__segment-card" variant={props.kind === SegmentKind.Type ? 'small' : 'large'} selected={selectedSegments.indexOf(segment.code) !== -1} />;
            })}
          </div>
        </div>

        <div className="grid__col--span3 content-block">
          {!superGroupMode && !superGroupDeleteMode ?
            <div>
              <h4 className="SegmentsPage__filter-title">Filter By</h4>
              <div className="side-options__label">Select</div>
              <div className="btn-group">
                <NavLink to="/segments/groups" className="btn" activeClassName="btn--purple" onClick={resetPostcode}>Groups</NavLink>
                <NavLink to="/segments/types" className="btn" activeClassName="btn--purple" onClick={resetPostcode}>Types</NavLink>
              </div>
              {props.supergroups.length < 99 ?
              <div className="SegmentsPage__super-grp-btn btn-group">
                <button className="btn" onClick={() => changeSuperGroupMode(true)}>Create Super Group</button>
              </div> : null}
              <div className="SegmentsPage__super-grp-btn btn-group">
                <button className="btn" onClick={() => changeSuperGroupDeleteMode(true)}>Delete Super Group</button>
              </div>
              <div className="side-options__label">POSTCODE</div>
              <form className="search-field input-group" onSubmit={postCodeSubmitHandler}>
                <input className="search-field__input input" name="postcode" placeholder="Search..." value={postcode} onChange={(e) => /^[0-9]{0,6}$/.test(e.target.value) ? setPostcode(e.target.value) : null} />
                <button className="search-field__btn" type="submit"><SearchIcon /></button>
              </form>
              <div className="side-options__label">REORDER</div>
              <div className="SegmentsPage__order-list">
                {segmentsSortOptions.map((option) => {
                  return <button onClick={() => setSortKey(option.key)} className={"SegmentsPage__order-item" + (option.key === sortKey ? ' SegmentsPage__order-item--active' : '')} key={option.key}>{option.key !== sortKey ? option.name : option.label}</button>
                })}
                <div className="btn-group">
                  <button onClick={() => setSortKey('')} className="btn">Reset</button>
                </div>
              </div>
            </div>: null}

          {superGroupMode ?
            <div>
              <div className="SegmentsPage__super-grp-btn btn-group">
                <button className="btn btn--purple" onClick={() => changeSuperGroupMode(false)}>Cancel Super Group</button>
              </div>
              <SuperGroupCreator 
               supergroups={props.supergroups}
               selectedSegments={selectedSegments}
               superGroupImage={superGroupImage}
               showImageSelectButton={true}
               onSuperGroupCreated={superGroupCreatedHandler}
               onSuperGroupCreationError={superGroupCreationErrorHanlder}
               onImageSelectRequested={() => setImageSelectVisible(true)}
              />
            </div>: null}

          {superGroupDeleteMode ?
            <div>
              <div className="SegmentsPage__super-grp-btn btn-group">
                <button className="btn" onClick={() => changeSuperGroupDeleteMode(false)}>Cancel Super Group Delete</button>
              </div>
              <div className="SegmentsPage__super-grp-btn btn-group">
                <button className="btn btn--purple" onClick={deleteSuperGroupsHandler}>Delete Super Groups</button>
              </div>
            </div>: null}
        </div>
      </div>
    </div>
  );
}
