import React, { 
  useState,
  useEffect,
  useCallback
} from 'react';
import { Bubble } from 'react-chartjs-2';
import { useParams, useHistory } from 'react-router-dom';
import { ScriptableContext, ChartType, InteractionItem } from 'chart.js/auto';

import Options from '../../Options';
import { 
  getSegmentByCode,
  getTopicAxis,
} from '../../Utils';
import {
  getLabelCanvas
} from '../../chartjsPlugins/utils';

import Page404 from '../Page404/Page404';
/* Components */
import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';
import SegmentPageTitleBar from '../../components/SegmentPageTitleBar/SegmentPageTitleBar';
import ActionsBar from '../../components/ActionsBar/ActionsBar';
import DataSelector from '../../components/DataSelector/DataSelector';
import Accordion from '../../elements/Accordion/Accordion';
import AccordionItem from '../../elements/Accordion/AccordionItem';

import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ITopicAxis from '../../interfaces/ITopicAxis';
import IMeasurableTopicMeta from '../../interfaces/IMeasurableTopicMeta';
import SegmentKind from '../../enums/SegmentKind';

import treeSegmentsData from '../../data/treeSegmentsData';
import treeCurves from '../../chartjsPlugins/treeCurves';
import axisLabels from '../../chartjsPlugins/axisLabels';

import './SegmentTreePage.css';

const treeAxisLabels = {
  top: {
    left: {
      label: 'High Affluence',
      align: 'center'
    },
    middle: {
      label: '',
      align: 'center'
    },
    right: {
      label: 'High Affluence',
      align: 'center'
    },
  },
  bottom: {
    left: {
      label: 'Low Affluence',
      align: 'center'
    },
    middle: {
      label: '',
      align: 'center'
    },
    right: {
      label: 'Bottom Affluence',
      align: 'center'
    },
  },
  left: {
    top: {
      label: 'Youthful',
      align: 'center'
    },
    middle: {
      label: '',
      align: 'center'
    },
    bottom: {
      label: 'Youthful',
      align: 'center'
    },
  },
  right: {
    top: {
      label: 'Mature',
      align: 'center',
      flip: true
    },
    middle: {
      label: '',
      align: 'center',
      flip: true
    },
    bottom: {
      label: 'Mature',
      align: 'center',
      flip: true
    },
  },
};

const bubbleOptions = {
  animation: false,
  maintainAspectRatio: false,
  scales: {
    x: {
      min: 0, max: 500,
      ticks: {
        stepSize: 250,
        display: false
      },
      grid: {
        tickLength: 0,
        color: '#ddd',
        borderColor: '#ddd'
      }
    },
    y: {
      min: 0, max: 500,
      reverse: true,
      ticks: {
        stepSize: 250,
        display: false
      },
      grid: {
        tickLength: 0,
        color: '#ddd',
        borderColor: '#ddd'
      },
    }
  },
  elements: {
    point: {
      pointStyle: function(context: ScriptableContext<ChartType>): HTMLCanvasElement | "circle" { return 'circle'; }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    },
    treeCurves: {
      display: true,
      treeSegmentsData
    },
    axisLabels: treeAxisLabels
  },
  layout: {
    padding: {
      top: 30,
      bottom: 30,
      left: 25,
      right: 25
    }
  },
  onHover: function(e: any, chartElement: any) {},
  onClick: function(e: any, chartElement: any) {},
};

const plugins = [
  treeCurves,
  axisLabels,
];

const treeGroupsData = {
  datasets: Object.keys(treeSegmentsData).filter(k => k.length === 1).map(code => { return {
    label: code,
    data: [{x: treeSegmentsData[code].x * (500/796), y: treeSegmentsData[code].y * (500/514), r: 15}]
  }}) 
};

const treeTypesData = {
  datasets: Object.keys(treeSegmentsData).filter(k => k.length > 1).map(code => { return {
    label: code,
    data: [{x: treeSegmentsData[code].x * (500/796), y: treeSegmentsData[code].y * (500/514), r: 15}]
  }}) 
};

enum DataSourceKind {
  Tree, Custom
};

interface ISegmentTreePageProps extends ISegmentPageProps {
  //variablesList: IVariableMeta[]
}

function getMeasurableTopicLabel(measurableTopics: IMeasurableTopicMeta[] | null, topicName: string) {
  const labels = { start_label: '', end_label: '' };
  if(measurableTopics === null) return labels;
  const topic = measurableTopics.find(m => m.topic === topicName);
  if(topic === undefined) return labels;
  return { start_label: topic.start_label, end_label: topic.end_label };
}

function measurableTopicToVariableMeta(topic: IMeasurableTopicMeta) {
  return {
    id: topic.id,
    category: topic.category,
    topic: topic.topic,
    variable: 'default'
  };
}

function getCategoryByTopic(measurableTopics: IMeasurableTopicMeta[], topic: string) {
  const measurableTopic = measurableTopics.find(m => m.topic === topic);
  if(measurableTopic === undefined) return '';
  return measurableTopic.category;
}

export default function SegmentTreePage(props: ISegmentTreePageProps) {
  const history = useHistory();
  const params : ISegmentPageParams = useParams();
  const segmentOverview : ISegmentBasic | undefined = getSegmentByCode(params.code, props.segments);

  const chartRef: React.RefObject<HTMLInputElement> = React.createRef();

  const [segmentKind, setSegmentKind] = useState<SegmentKind>(SegmentKind.Group);
  const [dataSourceKind, setDataSourceKind] = useState<DataSourceKind>(DataSourceKind.Tree);
  const [measurableTopics, setMeasurableTopics] = useState<IMeasurableTopicMeta[]>([]);
  //const [topicsToShow, setTopicsToShow] = useState<string[]>([]);

  const [axisTopics, setAxisTopics] = useState<{x: string, y: string}>({x:'Employment Status', y:'Children At Address - Any'});
  const [axisData, setAxisData] = useState<{x: ITopicAxis, y: ITopicAxis} | null>(null);

  bubbleOptions.onHover = function(e: any, chartElement: any) {
    if(chartRef.current === null) return;
    chartRef.current.style.cursor = chartElement.length === 0 ? 'default' : 'pointer';
  }

  bubbleOptions.onClick = function(e: any, chartElement: any) {
    if(chartRef.current === null) return;
    if(chartElement.length === 0) return;

    const idx = chartElement[0].datasetIndex;
    const code = e.chart.data.datasets[idx].label;

    history.push('/segments/' + code);
  }

  bubbleOptions.elements.point.pointStyle = function(context: ScriptableContext<ChartType>) {
    //console.log(context);
    const code: string = context.dataset.label === undefined ? '' : context.dataset.label;
    const pos: {x: number, y: number} = ((((context as unknown) as InteractionItem)).element as any).getCenterPoint(true);
    //console.log(pos);
    if(treeSegmentsData[code] === undefined) return 'circle';
    treeSegmentsData[code].left = pos.x;
    treeSegmentsData[code].top = pos.y;
    const segment: ISegmentBasic | undefined = getSegmentByCode(code, props.segments);
    if(segment === undefined) return 'circle';
    //console.log(segment);
    const canvas = getLabelCanvas(segment);
    return canvas !== null ? canvas : 'circle';
  };

  const [chartOptions, setChartOptions] = useState<any>(bubbleOptions);

  const updateCustomBubbleOptions = useCallback(() => {
    bubbleOptions.plugins.axisLabels = {
      top: {
        left: {
          label: '',
          align: 'left'
        },
        middle: {
          label: '',
          align: 'center'
        },
        right: {
          label: '',
          align: 'right'
        },
      },
      bottom: {
        left: {
          label: getMeasurableTopicLabel(measurableTopics, axisTopics.x).start_label,
          align: 'left'
        },
        middle: {
          label: axisTopics.x,
          align: 'center'
        },
        right: {
          label: getMeasurableTopicLabel(measurableTopics, axisTopics.x).end_label,
          align: 'right'
        },
      },
      left: {
        top: {
          label: getMeasurableTopicLabel(measurableTopics, axisTopics.y).start_label,
          align: 'left'
        },
        middle: {
          label: axisTopics.y,
          align: 'center'
        },
        bottom: {
          label: getMeasurableTopicLabel(measurableTopics, axisTopics.y).end_label,
          align: 'right'
        },
      },
      right: {
        top: {
          label: '',
          align: 'center',
          flip: true
        },
        middle: {
          label: '',
          align: 'center',
          flip: true
        },
        bottom: {
          label: '',
          align: 'center',
          flip: true
        },
      }
    };
  }, [measurableTopics, axisTopics.x, axisTopics.y]);

  function changeDataSourceKind(kind: DataSourceKind) {
    bubbleOptions.plugins.treeCurves.display = kind === DataSourceKind.Tree;
    if(kind === DataSourceKind.Tree) {
      bubbleOptions.plugins.axisLabels = treeAxisLabels;
    } else {
      updateCustomBubbleOptions();
    }
    setChartOptions(bubbleOptions);
    setDataSourceKind(kind);
  }
  //useEffect(() => {
  //  console.log('data kind effect');
  //  //bubbleOptions.plugins.treeCurves.display = dataSourceKind === DataSourceKind.Tree;
  //  setChartOptions(bubbleOptions);
  //}, [dataSourceKind]);

  useEffect(() => {
    if(measurableTopics.length > 0) return;
    (async function() {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/measurable-topics/`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data: IMeasurableTopicMeta[] = await response.json();
        setMeasurableTopics(data);
      }
    })();
  }, [measurableTopics]);

  useEffect(() => {
    if(axisData !== null && axisData.x.topic === axisTopics.x && axisData.y.topic === axisTopics.y) return;
    (async () => {
      const [xData, yData] = await Promise.all([
        getTopicAxis(axisTopics.x),
        getTopicAxis(axisTopics.y)
      ]);

      if(xData === null || yData === null) return;
      setAxisData({x: xData, y: yData});

      if(dataSourceKind === DataSourceKind.Custom) updateCustomBubbleOptions();
      setChartOptions(bubbleOptions);
    })();
  }, [axisTopics, axisData, dataSourceKind, updateCustomBubbleOptions]);

  const customGroupsData = {
    datasets: axisData === null ? [] : Object.keys(axisData.x).filter(k => k.length === 1).map(code => { return {
      label: code,
      data: [{x: (axisData.x[code as keyof ITopicAxis] as number) * (450/100) + 25, y: (100 - (axisData.y[code as keyof ITopicAxis] as number)) * (450/100) + 25, r: 15}]
    }}) 
  };
  
  const customTypesData = {
    datasets: axisData === null ? [] : Object.keys(axisData.x).filter(k => k.length > 1 && k !== 'topic').map(code => { return {
      label: code,
      data: [{x: (axisData.x[code as keyof ITopicAxis] as number) * (450/100) + 25, y: (100 - (axisData.y[code as keyof ITopicAxis] as number)) * (450/100) + 25, r: 15}]
    }}) 
  };


  if(props.segments.length === 0) return (
    <div className="SegmentTreePage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <strong>Loading...</strong>
    </div>
  );

  // If segment is not in the list it probably doesn't exist
  if(segmentOverview === undefined) return <Page404 />;

  return (
    <div className="SegmentTreePage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />

      <section className="SegmentTreePage__section container page-section page-section--top-space">
        <div className="content-block chart-block">
          <div className="chart-block__headers grid">
            <div className="chart-block__chart-header grid__col--span9">
              <h5>Compare Segments</h5>
              <ActionsBar />
            </div>
            <div className="chart-block__filter-header grid__col--span3">
              <h5>Chart Options</h5>
            </div>
          </div>
          <div className="chart-block__content grid">
            <div ref={chartRef} className="chart-block__chart-content grid__col--span9">
              <Bubble className="chart-block__chart" data={
                dataSourceKind === DataSourceKind.Tree ? 
                  (segmentKind === SegmentKind.Group ? treeGroupsData : treeTypesData)
                  :
                  (segmentKind === SegmentKind.Group ? customGroupsData : customTypesData)
              } options={chartOptions} plugins={plugins} />
            </div>
            <div className="chart-block__filter-content grid__col--span3">
              <div className="side-options__label">Select</div>
              <div className="btn-group">
                <button onClick={() => setSegmentKind(SegmentKind.Group)} className={"btn" + (segmentKind === SegmentKind.Group ? ' btn--purple' : '')}>Groups</button> 
                <button onClick={() => setSegmentKind(SegmentKind.Type)} className={"btn" + (segmentKind === SegmentKind.Type ? ' btn--purple' : '')}>Types</button> 
              </div>
    
              <div className="side-options__label">Data</div>
              <div className="btn-group">
                <button onClick={() => changeDataSourceKind(DataSourceKind.Tree)} className={"btn" + (dataSourceKind === DataSourceKind.Tree ? ' btn--purple' : '')}>Tree</button> 
                <button onClick={() => changeDataSourceKind(DataSourceKind.Custom)} className={"btn" + (dataSourceKind === DataSourceKind.Custom ? ' btn--purple' : '')}>Custom</button> 
              </div>

              {dataSourceKind === DataSourceKind.Custom ?
              <div className="SegmentTreePage__data-variables">
                <Accordion>
                  <AccordionItem title="X Axis">
                    <DataSelector variables={measurableTopics.map(t => measurableTopicToVariableMeta(t))} selectedCategory={getCategoryByTopic(measurableTopics, axisTopics.x)} selectedTopic={axisTopics.x} selectedVariable={-1} onTopicSelected={(topic) => setAxisTopics({x: topic, y: axisTopics.y})} />
                  </AccordionItem>
                  <AccordionItem title="Y Axis">
                    <DataSelector variables={measurableTopics.map(t => measurableTopicToVariableMeta(t))} selectedCategory={getCategoryByTopic(measurableTopics, axisTopics.y)} selectedTopic={axisTopics.y} selectedVariable={-1} onTopicSelected={(topic) => setAxisTopics({x: axisTopics.x, y: topic})} />
                  </AccordionItem>
                </Accordion>
              </div>
              : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
