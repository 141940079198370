import React, { useState } from 'react';
import {
  Link,
  NavLink,
  useParams
} from "react-router-dom";

//import { exportPdf } from '../../Utils';

import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentMap from '../../interfaces/ISegmentMap';
import SegmentKind from '../../enums/SegmentKind';

import {ReactComponent as HamburgerIcon} from '../../images/hamburger-icon.svg';
import {ReactComponent as CloseIcon} from '../../images/close-icon.svg';
import {ReactComponent as GridIcon} from '../../images/feather-icons/grid.svg';
import {ReactComponent as FileIcon} from '../../images/feather-icons/file.svg';
import {ReactComponent as UserIcon} from '../../images/feather-icons/user.svg';
import {ReactComponent as LayersIcon} from '../../images/feather-icons/layers.svg';
import {ReactComponent as ImageIcon} from '../../images/feather-icons/image.svg';
//import {ReactComponent as ShareIcon} from '../../images/feather-icons/share-2.svg';
import {ReactComponent as GitPullIcon} from '../../images/feather-icons/git-pull-request.svg';
import {ReactComponent as MapIcon} from '../../images/feather-icons/map.svg';
import {ReactComponent as ExportIcon} from '../../images/feather-icons/share.svg';
import {ReactComponent as PrinterIcon} from '../../images/feather-icons/printer.svg';

import './SegmentPageNav.css';

interface ISegmentPageNavProps {
  segments: ISegmentBasic[]
};

export function makeSegmentsMap(segments: ISegmentBasic[]) {
  const map: ISegmentMap[] = [];
  segments.forEach(group => {
    if(group.kind !== SegmentKind.Group) return;

    const children: string[] = [];
    segments.forEach(type => {
      if(type.kind !== SegmentKind.Type) return;
      if(group.code[0] !== type.code[0]) return;
      children.push(type.code);  
    });

    map.push({
      code: group.code,
      children
    });
  });

  return map;
}

export default function SegmentPageNav(props: ISegmentPageNavProps) {
  const params : ISegmentPageParams = useParams();
  const isSuperGroup: boolean = params.code[0] === '*';

  const [isOpen, setIsOpen] = useState(false);
  const [dialOpen, setDialOpen] = useState(false);

  const segmentsMap = makeSegmentsMap(props.segments);

  function toggleNav() {
    const state = !isOpen;
    setIsOpen(state);
    if(!state) {
      setDialOpen(false);
    }
  }

  function toggleDial() {
    const state = !dialOpen;
    setDialOpen(state);
    setIsOpen(state);
  }

  return (
    <div className={'SegmentPageNav' + (isOpen ? ' SegmentPageNav--open' : '')}>
      <button className="SegmentPageNav__toggle-btn" onClick={toggleNav} title="Browse">
        { isOpen ? (
          <CloseIcon /> 
          ) :
          <HamburgerIcon />
        }
        <span className="SegmentPageNav__toggle-btn-label">Browse</span>
      </button>
      <button className={"SegmentPageNav__btn SegmentPageNav__btn--dial" + (dialOpen ? ' SegmentPageNav__btn--active' : '')} onClick={toggleDial} title="Group / Type">
        <GridIcon />
        <span className="SegmentPageNav__btn-label">Group / Type</span>
      </button>
      <NavLink exact to={'/segments/' + params.code} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Portrait">
        <UserIcon />
        <span className="SegmentPageNav__btn-label">Portrait</span>
      </NavLink>
      {!isSuperGroup ?
      <NavLink exact to={'/segments/' + params.code + '/description'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Description">
        <FileIcon />
        <span className="SegmentPageNav__btn-label">Description</span>
      </NavLink> : null}
      <NavLink exact to={'/segments/' + params.code + '/map'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Map">
        <MapIcon />
        <span className="SegmentPageNav__btn-label">Map</span>
      </NavLink>
      {!isSuperGroup ?
      <NavLink exact to={'/segments/' + params.code + '/tree'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Tree">
        <GitPullIcon />
        <span className="SegmentPageNav__btn-label">Tree</span>
      </NavLink> : null}
      <NavLink exact to={'/segments/' + params.code + '/photos'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Photos">
        <ImageIcon />
        <span className="SegmentPageNav__btn-label">Photos</span>
      </NavLink>
      <NavLink exact to={'/segments/' + params.code + '/data'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Data">
        <LayersIcon />
        <span className="SegmentPageNav__btn-label">Data</span>
      </NavLink>
      
      <NavLink to={'/segments/' + params.code + '/export'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Export">
        <ExportIcon />
        <span className="SegmentPageNav__btn-label">Export</span>
      </NavLink>
      <NavLink to={'/segments/' + params.code + '/export?print=true'} className="SegmentPageNav__btn" activeClassName="SegmentPageNav__btn--active" title="Print">
        <PrinterIcon />
        <span className="SegmentPageNav__btn-label">Print</span>
      </NavLink>
      
      {dialOpen ? 
        <div className="SegmentPageNav__dial">
        {segmentsMap.map(group => {
          return (
            <div key={group.code} className="SegmentPageNav__dial-group">
              <Link className="SegmentPageNav__dial-group-link" to={`/segments/${group.code}`} onClick={toggleDial}>{group.code}</Link>
              {group.children.map(type => <Link key={type} className="SegmentPageNav__dial-type-link" to={`/segments/${type}`} onClick={toggleDial}>{type}</Link>)} 
            </div>
          );
        })}
        </div> : null}
    </div>
  );
}
