import React, {
  useState,
  useEffect
} from 'react';

interface IAccordionItemProps {
  title: string,
  children: React.ReactNode,
  active?: boolean,
  onPress?: React.MouseEventHandler,
  extraBtnText?: string,
  onExtraBtnPress?: React.MouseEventHandler
}

export default function AccordionItem(props: IAccordionItemProps) {
  const [height, setHeight] = useState<string>('auto'); 
  const titleRef: React.RefObject<HTMLInputElement> = React.createRef();
  const contentRef: React.RefObject<HTMLInputElement> = React.createRef();

  useEffect(() => {
    //console.log(titleRef.current, contentRef.current);
    if(titleRef.current === null || contentRef.current === null) return;
    const height = (titleRef.current.offsetHeight + contentRef.current.offsetHeight) + 'px';
    setHeight(height);
  }, [props.title, props.children, titleRef, contentRef]);

  return (
    <li onClick={props.onPress} className={"accordion__item" + (props.active !== undefined && props.active ? ' accordion__item--active' : '')} style={{ height: height }}>
      <div className="accordion__title" ref={titleRef}>
        {props.title}
        {props.extraBtnText !== undefined ? <button className="accordion__item-btn btn btn--purple btn--tiny" onClick={props.onExtraBtnPress}>{props.extraBtnText}</button> : null}
      </div>
      <section className="accordion__content condensed-scroll" ref={contentRef}>{props.children}</section>
    </li>
  );
}
