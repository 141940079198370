enum ExposureChannelKind {
  Television = "Television",
  Radio = "Radio",
  Outdoor = "Outdoor",
  Newspaper = "Newspaper",
  Internet = "Internet",
  SocialMedia = "Social Media"
}

export default ExposureChannelKind;

