import { useState, useEffect } from 'react';

import Options from '../../Options';

import IRestError from '../../interfaces/IRestError';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISegmentPhoto from '../../interfaces/ISegmentPhoto';
import ILooseObject from '../../interfaces/ILooseObject';

interface ISuperGroupOptions extends ILooseObject {
  name: string,
  code: string,
  description: string,
  image: string
};

const defaultSuperGroupOptions = {
  name: 'New Group',
  code: '',
  description: '',
  image: ''
};

interface ISuperGroupCreatorProps {
  supergroups: ISegmentBasic[],
  selectedSegments: string[],
  superGroupImage: ISegmentPhoto | null,
  showImageSelectButton: boolean,
  onSuperGroupCreated: () => void,
  onSuperGroupCreationError: (error: string) => void,
  onImageSelectRequested: () => void
}

export default function SuperGroupCreator(props: ISuperGroupCreatorProps) {
  const [superGroupOptions, setSuperGroupOptions] = useState<ISuperGroupOptions>(Object.assign(defaultSuperGroupOptions));

  useEffect(() => {
    setSuperGroupOptions(Object.assign(defaultSuperGroupOptions, {code: (props.supergroups.length + 1).toString().padStart(2, '0')}));
  }, [props.supergroups]);

  useEffect(() => {
    let newImage = '';
    if(props.superGroupImage !== null) {
      newImage = props.superGroupImage.id.toString();
    } 
    setSuperGroupOptions(previousState => {
      return { ...previousState, image: newImage  }
    });
  }, [props.superGroupImage]);

  function superGroupOptionChangeHandler(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
    if(event.target.hasAttribute('pattern')) {
      const regex = new RegExp('^' + event.target.getAttribute('pattern') + '$');
      if(!regex.test(event.target.value)) return;
    }
    const newSuperGroupOptions: ISuperGroupOptions = {...superGroupOptions};
    newSuperGroupOptions[event.target.name] = event.target.value;
    setSuperGroupOptions(newSuperGroupOptions);
  }

  function validateSuperGroupOptions() {
    if(superGroupOptions.name === '') {
      alert('Super Group name can\'t be empty');
      return false;
    }
    if(superGroupOptions.code === '') {
      alert('Super Group code can\'t be empty');
      return false;
    }
    if(props.selectedSegments.length < 2) {
      alert('At least two segments must be selected to create a Super Group');
      return false;
    }
    if(props.supergroups.find(s => s.code === '*' + superGroupOptions.code)) {
      alert('Supergroup with that code already exists');
      return false;
    }
    return true;
  }

  async function superGroupSubmitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(!validateSuperGroupOptions()) return;

    const customHeaders = Object.assign(Options.ApiHeaders);
    customHeaders.set('Content-Type', 'application/json');
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/supergroups/`, { 
      method: 'POST', 
      headers: customHeaders, 
      body: JSON.stringify(Object.assign(superGroupOptions, { segments: props.selectedSegments }))
    });

    if(response.ok) {
      // trigger callback to reload Super Groups
      alert('Super Group created');
      props.onSuperGroupCreated();
    } else {
      // inform user of the error
      const error: IRestError = await response.json();
      props.onSuperGroupCreationError(error.message);
    }
  }

  return (
    <form className="SuperGroupCreator" onSubmit={superGroupSubmitHandler}>
       <div className="side-options__label">ENTER SUPER GROUP NAME</div>
       <div className="input-group">
         <input className="input" type="text" name="name" value={superGroupOptions.name} onChange={(e) => superGroupOptionChangeHandler(e)}/>
       </div>
       <div className="side-options__label">Select Super Group Code</div>
       <div className="input-group SegmentsPage__code-input-container">
         <input className="input SegmentsPage__code-input" pattern="[A-Za-z0-9]{0,2}" maxLength={2} type="text" name="code" value={superGroupOptions.code} onChange={(e) => superGroupOptionChangeHandler(e)}/>
       </div>
       <div className="side-options__label">Enter Super Group Description</div>
       <div className="input-group">
         <textarea className="input" name="description" value={superGroupOptions.description} onChange={(e) => superGroupOptionChangeHandler(e)}/>
       </div>
       <div className="side-options__label">Select Super Group Image</div>
       {props.superGroupImage !== null ?
       <img className="SegmentsPage__super-grp-img" alt={superGroupOptions.name} src={props.superGroupImage.thumbnail}/> : null}
       <div className="btn-group">
         <input className="input" type="hidden" name="image" value={superGroupOptions.image} onChange={(e) => superGroupOptionChangeHandler(e)}/>
         {props.showImageSelectButton ? 
         <button type="button" onClick={() => props.onImageSelectRequested()} className="btn">Select Image</button> : null}
       </div>
       <div className="SegmentsPage__super-grp-btn btn-group">
         <button type="submit" className="btn btn--purple">Create Super Group</button>
       </div>
    </form>
  )
}
