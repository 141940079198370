import { 
  useState,
  useEffect,
} from 'react';
import { 
  useLocation,
  useHistory,
  Link
} from 'react-router-dom';

import Options from '../../Options';

/* Components */
import ModalWindow from '../../elements/ModalWindow/ModalWindow';
import PhotosGrid from '../../components/PhotosGrid/PhotosGrid';
import SuperGroupCreator from '../../components/SuperGroupCreator/SuperGroupCreator';
import SegmentCard from '../../components/SegmentCard/SegmentCard';
import SearchResultSegment from '../../components/SearchResultSegment/SearchResultSegment';

import {ReactComponent as ChevronLeftIcon} from '../../images/feather-icons/chevron-left.svg';

import IVariableMeta from '../../interfaces/IVariableMeta';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ISegmentPhoto from '../../interfaces/ISegmentPhoto';
import ISavedSearch from '../../interfaces/ISavedSearch';
import IRestError from '../../interfaces/IRestError';

import './AudienceSearchResultsPage.css'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getCurrentDate() {
  const now = new Date(Date.now());
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear();
  return `${day}/${month}/${year}`;
}

function parseVariables(query: string | null) {
  if(query === null) return [];
  return query.split(',').map(v => parseInt(v));
}

interface IAudienceSearchResultItem {
  code: string,
  score: number
};

interface IAudienceSearchResultsPageProps {
  variablesList: IVariableMeta[],
  segments: ISegmentBasic[],
  supergroups: ISegmentBasic[]
};

export default function AudienceSearchResultsPage(props: IAudienceSearchResultsPageProps) {
  const history = useHistory();

  const query = useQuery();
  const idQueryParam = query.get('id');
  const thresholdQueryParam = query.get('threshold');
  const kindQueryParam = query.get('kind');
  const variablesQueryParam = query.get('variables');

  const id: number = idQueryParam !== null ? parseInt(idQueryParam) : -1;
  const threshold: number = thresholdQueryParam !== null ? parseInt(thresholdQueryParam) : 10;
  //let date: string = getCurrentDate();
  let kind: string = kindQueryParam !== null ? kindQueryParam : 'group';
  if(['group', 'type'].indexOf(kind) === -1) kind = 'group';
  //let variables: number[] = useMemo(() => variablesQueryParam !== null ? variablesQueryParam.split(',').map(v => parseInt(v)) : [], [variablesQueryParam]);

  const [searchName, setSearchName] = useState<string>('');
  const [searchDate, setSearchDate] = useState<string>(getCurrentDate());
  const [searchVariables, setSearchVariables] = useState<number[]>(parseVariables(variablesQueryParam));
  const [searchResults, setSearchResults] = useState<IAudienceSearchResultItem[]>([]);
  const [expandedView, setExpandedView] = useState<boolean>(false);

  const [superGroupWindowVisible, setSuperGroupWindowVisible] = useState<boolean>(false);
  const [superGroupImage, setSuperGroupImage] = useState<ISegmentPhoto | null>(null);

  function searchNameChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchName(event.target.value);
  }

  useEffect(() => {
    if(id === -1) return; // this is not a saved search 
    // requesting saved search details
    (async () => {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/saved-searches/${id}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data: ISavedSearch = await response.json();
        setSearchDate(data.date);
        setSearchName(data.name);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const customHeaders = Object.assign(Options.ApiHeaders);
      customHeaders.set('Content-Type', 'application/json');
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/search-audiences`, {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify({
          kind,
          variables: searchVariables,
          threshold
        })
      });
      if(response.ok) {
        const data: IAudienceSearchResultItem[] = await response.json();
        setSearchResults(data);
      }
    })();
  }, [kind, threshold, searchVariables]);

  const searchResultSegments: ISegmentBasic[] = [];
  const searchStats = {
    totalPopulation: 0,
    //email: 0, directMail: 0, phone: 0
  };

  const searchResultCodes = searchResults.map(s => s.code); 
  props.segments.forEach(s => {
    if(searchResultCodes.indexOf(s.code) === -1) return;
    searchResultSegments.push(s);
    searchStats.totalPopulation += s.household;
  });

  async function saveSearch() {
    if(searchName.trim() === '') {
      alert('Search name can\'t be empty');
      return;
    }
    const customHeaders = Object.assign(Options.ApiHeaders);
    customHeaders.set('Content-Type', 'application/json');
    // FIXME: It might be better idea to store search temporary 
    // on a server side and to make the search saved permanently
    // user need to request it by ID.
    // Current version allows arbitrary details modification. 
    // Though this shouldn't have any security risk
    const response = await fetch(`${Options.ApiBase}/wp-json/experian/saved-searches/${id !== -1 ? id : ''}`, {
      method: id === -1 ? 'POST' : 'PUT', // PUT for search update
      headers: customHeaders,
      body: JSON.stringify({
        name: searchName,
        kind,
        threshold,
        variables: searchVariables,
        segments: searchResultCodes
      })
    });
    if(response.ok) {
      alert('Search ' + (id === -1 ? 'Saved' : 'Updated'));
      const data: { new_saved_search_id: number } = await response.json();
      const path = `/identify/search?id=${data.new_saved_search_id}&kind=${kind}&threshold=${threshold}&variables=${searchVariables.join()}`;
      history.push(path);
      //setSearchSaved(true);
    } else {
      // inform user of the error
      const error: IRestError = await response.json();
      alert('Error during saving search: ' + error.message);
    }
  }

  async function deleteSearch() {
    if(!window.confirm('Are you sure you want to delete the search?')) return;

    const response = await fetch(`${Options.ApiBase}/wp-json/experian/saved-searches/${id}`, {
      method: 'DELETE',
      headers: Options.ApiHeaders
    });

    if(response.ok) {
      alert('The search was deleted');
      history.push('/identify/');
    } else {
      const error: IRestError = await response.json();
      alert('Error during deleting search: ' + error.message);
    }
  }

  function removeVariable(id: number) {
    const newSearchVariables = [...searchVariables];
    const idx = newSearchVariables.indexOf(id);
    if(idx === -1) return;
    newSearchVariables.splice(idx, 1);
    setSearchVariables(newSearchVariables);
  }

  function superGroupCreatedHandler() {
    setSuperGroupWindowVisible(false);
  }

  return (
    <div className="AudienceSearchResultsPage page page--no-top-padding">
      <div className="container">
        <Link className="AudienceSearchResultsPage__back-link"to="/identify/"><ChevronLeftIcon className="AudienceSearchResultsPage__back-icon" /> Back to Audience Search</Link>
      </div>
      <ModalWindow active={superGroupWindowVisible} onCloseBtnPress={() => setSuperGroupWindowVisible(false)}>
        <div className="grid">
          <div className="grid__col--span9">
            <h2>Select photo for the Super Group</h2>
            <div className="AudienceSearchResultsPage__photos-container condensed-scroll">
            {superGroupWindowVisible ?
              <PhotosGrid segments={searchResultCodes} onPhotoPress={(photo) => setSuperGroupImage(photo)}/> : null}
            </div>
          </div>
          <div className="grid__col--span3">
            <h4>{searchResultCodes.length} {kind === 'group' ? 'Groups' : 'Types'} Selected</h4>
            <SuperGroupCreator 
             supergroups={props.supergroups}
             selectedSegments={searchResultCodes}
             superGroupImage={superGroupImage}
             showImageSelectButton={false}
             onSuperGroupCreated={superGroupCreatedHandler}
             onSuperGroupCreationError={(message) => alert('Error while creating supergroup: ' + message)}
             onImageSelectRequested={() => {}}
            />
          </div>
        </div>
      </ModalWindow>
      <section className="AudienceSearchResultsPage__section container page-section content-block">
        <div className="AudienceSearchResultsPage__search-info grid">
          <div className="AudienceSearchResultsPage__search-details grid__col--span5">
            <h2 className="AudienceSearchResultsPage__title">Search Details</h2>
            <table className="AudienceSearchResultsPage__search-details-table">
              <tbody>
                <tr>
                  <th>Search Name</th>
                  <td><input className="input input--small" type="text" name="search_name" value={searchName} onChange={searchNameChangeHandler} /></td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td><input className="input input--small" type="text" name="search_date" readOnly={true} value={searchDate} /></td>

                </tr>
                <tr>
                  <th>Type/Group</th>
                  <td><input className="AudienceSearchResultsPage__kind-field input input--small" type="text" name="search_kind" readOnly={true} value={kind} /></td>
                </tr>
              </tbody>
            </table>
            <div className="AudienceSearchResultsPage__extra-options btn-group">
              <button className="btn btn--purple btn--small" style={{maxWidth: 140}} onClick={saveSearch}>{id === -1 ? 'Save' : 'Update'} Search</button>
              {id !== -1 ? <button className="btn btn--small" onClick={deleteSearch}>Delete Search</button> : null}
            </div>
          </div>
          <div className="AudienceSearchResultsPage__search-criteria grid__col--span5">
            <h2 className="AudienceSearchResultsPage__title">Search Criteria</h2>
            {props.variablesList.filter(v => searchVariables.indexOf(v.id) !== -1).map(variable =>
              <div key={variable.id} className="tag" onClick={() => removeVariable(variable.id)}>{variable.topic}: {variable.variable}</div>)}
          </div>
        </div>
        <div className="AudienceSearchResultsPage__search-results">
          <h2 className="AudienceSearchResultsPage__title">Search Results</h2>
          <div className="AudienceSearchResultsPage__extra-options btn-group">
            <button className="btn btn--purple btn--small" onClick={() => setExpandedView(!expandedView)}>{expandedView ? 'Condensed' : 'Expanded'} View</button>
            <button className="btn btn--purple btn--small" onClick={() => setSuperGroupWindowVisible(true)}>Create Supergroup</button>
          </div>
          <div className="AudienceSearchResultsPage__stats">
            <span className="AudienceSearchResultsPage__stat">Total Population: <strong>{searchStats.totalPopulation.toFixed(1)}%</strong></span>
            {/*<span className="AudienceSearchResultsPage__stat">Email: <strong>{searchStats.email}</strong></span>
            <span className="AudienceSearchResultsPage__stat">Direct Mail: <strong>{searchStats.directMail}</strong></span>
            <span className="AudienceSearchResultsPage__stat">Phone: <strong>{searchStats.phone}</strong></span>*/}
          </div>
          <div className="grid">
            <div className="AudienceSearchResultsPage__segments grid__col--span9">
              {searchResultSegments.map(segment => <SegmentCard className="AudienceSearchResultsPage__segment" variant="search-result" key={segment.id} segment={segment} />)}
            </div>
          </div>
        </div>
        {expandedView ? 
        <div className="AudienceSearchResultsPage__expanded-view">
          {searchResultSegments.map(s => <SearchResultSegment key={s.id} segment={s} />)}
        </div> : null}
      </section>
    </div>
  );
}
