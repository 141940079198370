import { 
  useState,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';

import { 
  getSegmentByCode,
  getTopicVariables,
  getVariableCompareData
} from '../../Utils';

import Page404 from '../Page404/Page404';
/* Components */
import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';
import SegmentPageTitleBar from '../../components/SegmentPageTitleBar/SegmentPageTitleBar';

import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ITopicVariable from '../../interfaces/ITopicVariable';
import IVariableCompareData from '../../interfaces/IVariableCompareData';
import IVariableMeta from '../../interfaces/IVariableMeta';
import ISegmentBasic from '../../interfaces/ISegmentBasic';

import VariableView from '../../components/VariableView/VariableView';
import VariableCompare from '../../components/VariableCompare/VariableCompare';

import './SegmentDataPage.css';

interface ISegmentDataPageProps extends ISegmentPageProps {
  variablesList: IVariableMeta[]
};

export default function SegmentDataPage(props: ISegmentDataPageProps) {
  const params : ISegmentPageParams = useParams();
  const isSuperGroup = params.code[0] === '*';
  const segmentOverview : ISegmentBasic | undefined = isSuperGroup ? getSegmentByCode(params.code, props.supergroups) : getSegmentByCode(params.code, props.segments);
  const [variableViewData, setVariableViewData] = useState<ITopicVariable[] | null>(null);
  const [variableCompareData, setVariableCompareData] = useState<IVariableCompareData | null>(null);
  const [currentTopic, setCurrentTopic] = useState<string>('Age')
  const [currentVariable, setCurrentVariable] = useState<number>(-1);

  const variablesList = props.variablesList;
  //const [variablesList, setVariablesList] = useState<IVariableMeta[]>([]);

  useEffect(() => {
    if(variableViewData !== null && variableViewData.length > 0 && variableViewData[0].topic === currentTopic) return;
    (async function() {
      const vars = await getTopicVariables(params.code, currentTopic);
      setVariableViewData(vars);
    })();
  }, [params.code, variableViewData, currentTopic]);

  useEffect(() => {
    if(variableCompareData !== null && variableCompareData.id === currentVariable) return;
    if(currentVariable === -1) {
      setVariableCompareData(null);
      return;
    }
    (async function() {
      const comp = await getVariableCompareData(currentVariable);
      setVariableCompareData(comp);
    })();
  }, [variableCompareData, currentVariable]);

  // useEffect(() => {
  //   if(variablesList.length > 0) return;
  //   (async function() {
  //     const response = await fetch(`${Options.ApiBase}/wp-json/experian/variables`);
  //     if(response.ok) {
  //       const vars : IVariableMeta[] = await response.json();
  //       setVariablesList(vars);
  //     }
  //   })();
  // }, [variablesList]);

  if(props.segments.length === 0) return (
    <div className="SegmentDataPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <strong>Loading...</strong>
    </div>
  );

  // If segment is not in the list it probably doesn't exist
  if(segmentOverview === undefined) return <Page404 />;

  return (
    <div className="SegmentDataPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />

      {variableViewData !== null ?
      <section className="SegmentDataPage__section container page-section page-section--top-space">
        <VariableView data={variableViewData} onVariableSelected={(id) => setCurrentVariable(id)} onTopicSelected={(topic) => {setCurrentTopic(topic); setCurrentVariable(-1)}} showDataSelector dataSelectorVariables={variablesList}  />
      </section> : null}

      <div className="print__page-break"/>

      {variableCompareData !== null ?
      <section className="SegmentDataPage__section container page-section page-section--top-space">
        <VariableCompare data={variableCompareData} dataSelectorVariables={variablesList} onVariableSelected={(id) => setCurrentVariable(id)} showDataSelector showDataSelectorVariables={true} />
      </section>: null}
    </div>
  )
}
