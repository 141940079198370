import {
  useState,
  useEffect
} from 'react';

import {
  getVariableCompareData
} from '../../Utils';

import VariableCompare from '../../components/VariableCompare/VariableCompare';

import IVariableMeta from '../../interfaces/IVariableMeta';
import IVariableCompareData from '../../interfaces/IVariableCompareData';

interface IComparePageProps {
  variablesList: IVariableMeta[]
};

export default function ComparePage(props: IComparePageProps) {
  const [variableCompareData, setVariableCompareData] = useState<IVariableCompareData | null>(null);
  const [currentVariable, setCurrentVariable] = useState<number>(47);

  useEffect(() => {
    if(variableCompareData !== null && variableCompareData.id === currentVariable) return;
    if(currentVariable === -1) {
      setVariableCompareData(null);
      return;
    }
    (async function() {
      const comp = await getVariableCompareData(currentVariable);
      setVariableCompareData(comp);
    })();
  }, [variableCompareData, currentVariable]);

  return (
    <div className="ComparePage page">
      <section className="ComparePage__section container page-section">
        {variableCompareData !== null ?
          <VariableCompare data={variableCompareData} dataSelectorVariables={props.variablesList} onVariableSelected={(id) => setCurrentVariable(id)} showDataSelector showDataSelectorVariables={true} />
        : null}
      </section>
    </div>
  );
}
