import Chart from 'chart.js/auto';
import { fontFamily } from '../Options';

import ISegmentBasic from '../interfaces/ISegmentBasic';

export function getLabelCanvas(segment: ISegmentBasic, selected: boolean = false) {
  //const canvas = new HTMLCanvasElement();
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const size = segment.code.length === 1 ? { x: 80, y: 46 } : { x: 35, y: 20 };
  canvas.width = size.x; canvas.height = size.y;
  const ctx = canvas.getContext('2d');

  if(ctx === null) return null;

  const fontSize = segment.code.length === 1 ? 16 : 12;
  ctx.beginPath();
  ctx.rect(0, 0, size.x, size.y);
  ctx.fillStyle = segment.primary_hex;
  ctx.fill();

  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.font = `bold ${fontSize}px ${fontFamily}`;
  ctx.fillText(segment.code, size.x / 2, size.y / 2);

  return canvas;
}

export function getPluginOption(chart: Chart, pluginName: string, option: string, defaultVal: any = null) {
  if(chart === undefined || chart.options === undefined || chart.options.plugins === undefined) return defaultVal;
  if((chart.options.plugins as any)[pluginName] === undefined) return defaultVal;
  if((chart.options.plugins as any)[pluginName][option] === undefined) return defaultVal;
  return (chart.options.plugins as any)[pluginName][option]; 
}
