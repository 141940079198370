import React from 'react';
import { NavLink } from 'react-router-dom';

import './Page404.css';

import {ReactComponent as Logo404} from '../../images/404-logo.svg';

export default function Page404() {
  function goBack() {
    window.history.back();
  }

  return (
    <div className="Page404 page">
      <Logo404 className="Page404__logo" />

      <div className="Page404__links">
        <button onClick={goBack} className="Page404__link">Go Back</button>
        <NavLink to="/" className="Page404__link">Dashboard</NavLink>
      </div>
    </div>
  )
}
