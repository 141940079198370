import ILooseObject from '../interfaces/ILooseObject';

const treeSegmentsData: ILooseObject = {
  // GROUPS
  'G': {x: 118, y: 269, top: 0, left: 0, parent: null},
    'F': {x: 200, y: 206, top: 0, left: 0, parent: 'G'},
      'C': {x: 202, y: 116, top: 0, left: 0, parent: 'F'},
      'B': {x: 307, y: 187, top: 0, left: 0, parent: 'F'},
        'A': {x: 481, y: 96, top: 0, left: 0, parent: 'B'},
        'E': {x: 525, y: 201, top: 0, left: 0, parent: 'B'},
          'D': {x: 615, y: 137, top: 0, left: 0, parent: 'E'},
    'H': {x: 190, y: 350, top: 0, left: 0, parent: 'G'},
      'L': {x: 413, y: 421, top: 0, left: 0, parent: 'H'},
      'J': {x: 324, y: 313, top: 0, left: 0, parent: 'H'},
        'K': {x: 438, y: 321, top: 0, left: 0, parent: 'J'},
        'I': {x: 395, y: 255, top: 0, left: 0, parent: 'J'},
          'M': {x: 647, y: 272, top: 0, left: 0, parent: 'I'},
            'N': {x: 563, y: 357, top: 0, left: 0, parent: 'M'},
  // TYPES
  'G20': {x: 204, y: 268, top: 0, left: 0, parent: null},
    'G22': {x: 277, y: 270, top: 0, left: 0, parent: 'G20'},
    'F19': {x: 185, y: 221, top: 0, left: 0, parent: 'G20'},
      'F18': {x: 251, y: 238, top: 0, left: 0, parent: 'F19'},
      'F17': {x: 231, y: 189, top: 0, left: 0, parent: 'F19'},
        'C09': {x: 223, y: 141, top: 0, left: 0, parent: 'F17'},
          'C08': {x: 287, y: 97, top: 0, left: 0, parent: 'C09'},
          'C10': {x: 162, y: 167, top: 0, left: 0, parent: 'C09'},
        'B06': {x: 282, y: 207, top: 0, left: 0, parent: 'F17'},
          'B05': {x: 338, y: 171, top: 0, left: 0, parent: 'B06'},
            'A02': {x: 420, y: 117, top: 0, left: 0, parent: 'B05'},
              'A01': {x: 504, y: 88, top: 0, left: 0, parent: 'A02'},
              'A04': {x: 492, y: 131, top: 0, left: 0, parent: 'A02'},
              'A03': {x: 564, y: 107, top: 0, left: 0, parent: 'A04'},
            'B07': {x: 380, y: 193, top: 0, left: 0, parent: 'B05'},
              'E14': {x: 442, y: 195, top: 0, left: 0, parent: 'B07'},
                'J31': {x: 367, y: 231, top: 0, left: 0, parent: 'E14'},
                'K35': {x: 475, y: 232, top: 0, left: 0, parent: 'E14'},
                'E15': {x: 511, y: 172, top: 0, left: 0, parent: 'E14'},
                  'E16': {x: 549, y: 209, top: 0, left: 0, parent: 'E15'},
                  'D11': {x: 604, y: 140, top: 0, left: 0, parent: 'E15'},
                    'D12': {x: 658, y: 180, top: 0, left: 0, parent: 'D11'},
                    'D13': {x: 689, y: 130, top: 0, left: 0, parent: 'D11'},
    'I28': {x: 176, y: 313, top: 0, left: 0, parent: 'G20'},
      'I30': {x: 358, y: 285, top: 0, left: 0, parent: 'I28'},
        'K36': {x: 446, y: 301, top: 0, left: 0, parent: 'I30'},
        'J33': {x: 376, y: 333, top: 0, left: 0, parent: 'I30'},
          'J34': {x: 437, y: 367, top: 0, left: 0, parent: 'J33'},
            'K37': {x: 494, y: 324, top: 0, left: 0, parent: 'J34'},
        'I29': {x: 492, y: 266, top: 0, left: 0, parent: 'I30'},
          'M44': {x: 661, y: 224, top: 0, left: 0, parent: 'I29'},
            'M43': {x: 616, y: 283, top: 0, left: 0, parent: 'M44'},
            'M46': {x: 709, y: 184, top: 0, left: 0, parent: 'M44'},
            'M45': {x: 677, y: 320, top: 0, left: 0, parent: 'M44'},
              'M47': {x: 712, y: 363, top: 0, left: 0, parent: 'M45'},
              'N48': {x: 544, y: 297, top: 0, left: 0, parent: 'M45'},
                'N50': {x: 496, y: 376, top: 0, left: 0, parent: 'N48'},
                'N49': {x: 571, y: 357, top: 0, left: 0, parent: 'N48'},
                  'N51': {x: 563, y: 406, top: 0, left: 0, parent: 'N49'},
    'G21': {x: 136, y: 273, top: 0, left: 0, parent: 'G20'},
      'G23': {x: 102, y: 295, top: 0, left: 0, parent: 'G21'},
      'H26': {x: 120, y: 365, top: 0, left: 0, parent: 'G21'},
        'H24': {x: 192, y: 351, top: 0, left: 0, parent: 'H26'},
          'H25': {x: 261, y: 351, top: 0, left: 0, parent: 'H24'},
            'H27': {x: 311, y: 379, top: 0, left: 0, parent: 'H25'},
            'J32': {x: 309, y: 324, top: 0, left: 0, parent: 'H25'},
            'L39': {x: 246, y: 406, top: 0, left: 0, parent: 'H25'},
              'L38': {x: 170, y: 420, top: 0, left: 0, parent: 'L39'},
              'L40': {x: 417, y: 408, top: 0, left: 0, parent: 'L39'},
                'L41': {x: 620, y: 432, top: 0, left: 0, parent: 'L40'},
                'L42': {x: 307, y: 434, top: 0, left: 0, parent: 'L40'},

};
export default treeSegmentsData;
