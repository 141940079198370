import { getLargestVariableValue } from '../../Utils';

import ISegmentOverviewData from '../../interfaces/ISegmentOverviewData';

import './SegmentPageHero.css';

interface ISegmentPageHeroProps {
  name: string,
  description: string,
  heroImage: string,
  overviewData: ISegmentOverviewData | null,
}

export default function SegmentPageHero(props : ISegmentPageHeroProps) {
  return (
    <div className="SegmentPage__hero" style={{
      backgroundImage: props.heroImage !== '' ? `linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.3) 100%), url(${props.heroImage})` : undefined
    }}>
      <div className="container grid">
        <div className="SegmentPage__hero-meta grid__col--span10">
          <h1 className="heading--display SegmentPage__hero-title">{props.name}</h1>
          <div className="SegmentPage__hero-description">{props.description}</div>
        </div>

        {props.overviewData !== null ?
        <div className="SegmentPage__hero-stats grid__col--span2">
          <div className="SegmentPage__hero-stat">
            <h5 className="SegmentPage__hero-stat-value">{getLargestVariableValue(props.overviewData.age)}</h5>
            <span className="SegmentPage__hero-stat-title">Age</span>
          </div>
          <div className="SegmentPage__hero-stat">
            <h5 className="SegmentPage__hero-stat-value">{getLargestVariableValue(props.overviewData.annualHouseholdIncome.filter(v => v.source === 'Census'))}</h5>
            <span className="SegmentPage__hero-stat-title">Household Income</span>
          </div>
          <div className="SegmentPage__hero-stat">
            <h5 className="SegmentPage__hero-stat-value">{getLargestVariableValue(props.overviewData.propertyTenure)}</h5>
            <span className="SegmentPage__hero-stat-title">Propery Tenure</span>
          </div>
          <div className="SegmentPage__hero-stat">
            <h5 className="SegmentPage__hero-stat-value">{getLargestVariableValue(props.overviewData.remoteness)}</h5>
            <span className="SegmentPage__hero-stat-title">Remoteness</span>
          </div>
        </div>
        : null }
      </div>
    </div>
  )
}
