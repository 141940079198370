import { 
  useState,
  useEffect,
  useCallback
} from 'react';
import { useParams } from 'react-router-dom';
import { 
  GoogleMap, 
  useJsApiLoader,
  Polygon,
} from '@react-google-maps/api';

import Options from '../../Options';
import { 
  getSegmentByCode
} from '../../Utils';

import Page404 from '../Page404/Page404';
/* Components */
import SegmentPageNav from '../../components/SegmentPageNav/SegmentPageNav';
import SegmentPageTitleBar from '../../components/SegmentPageTitleBar/SegmentPageTitleBar';

import ISegmentPageParams from '../../interfaces/ISegmentPageParams';
import ISegmentPageProps from '../../interfaces/ISegmentPageProps';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ILooseObject from '../../interfaces/ILooseObject';

import './SegmentMapPage.css'

const maxOpacity = 0.85;
//const startColor = {r: 151, g: 45, b: 128};
//const endColor = {r: 221, g: 71, b: 141};
const polygonOptions = {
    fillColor: '#972D80',
    fillOpacity: maxOpacity,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 0,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
};

// Black and White style for printing: https://snazzymaps.com/style/151/ultra-light-with-labels
// const mapStyles =  [
//   { elementType: "geometry", stylers: [{ color: "#05364e" }] },
//   { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
//   { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
//   {
//     featureType: "administrative.locality",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#d59563"  }],
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#d59563"  }],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "geometry",
//     stylers: [{ color: "#263c3f"  }],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#6b9a76"  }],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry",
//     stylers: [{ color: "#38414e"  }],
//   },
//   {
//     featureType: "road",
//     elementType: "geometry.stroke",
//     stylers: [{ color: "#212a37"  }],
//   },
//   {
//     featureType: "road",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#9ca5b3"  }],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry",
//     stylers: [{ color: "#746855"  }],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry.stroke",
//     stylers: [{ color: "#1f2835"  }],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#f3d19c"  }],
//   },
//   {
//     featureType: "transit",
//     elementType: "geometry",
//     stylers: [{ color: "#2f3948"  }],
//   },
//   {
//     featureType: "transit.station",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#d59563"  }],
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [{ color: "#101622"  }],
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#456164"  }],
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text.stroke",
//     stylers: [{ color: "#17263c"  }],
//   }
// ];

const mapOptions: google.maps.MapOptions = {
  //styles: mapStyles
};

const center = {lat: -25.344, lng: 131.036};

interface IMapArea {
  value: number,
  label: string,
  geometry: [number, number][][][]
};

interface IMapPolygon {
  value: number,
  label: string,
  paths: google.maps.LatLng[][]
};

function getMapLayerByZoom(zoom: number) {
  if(zoom >= 10) return 's2';
  if(zoom >= 8) return 's3';
  if(zoom >= 6) return 's4';
  return 'state';
}

// interface IColor {
//   r: number, g: number, b: number;
// }

// function getGradientColor(startColor: IColor, endColor: IColor, position: number) {
//   return {
//     r: (endColor.r - startColor.r) * position + startColor.r,
//     g: (endColor.g - startColor.g) * position + startColor.g,
//     b: (endColor.b - startColor.b) * position + startColor.b,
//   };
// }

const mapLayerCache: ILooseObject = {};
let maxValue = 0; 
let minValue = Infinity;

export default function SegmentMapPage(props: ISegmentPageProps) {
  const params : ISegmentPageParams = useParams();
  const isSuperGroup = params.code[0] === '*';
  const segmentOverview : ISegmentBasic | undefined = isSuperGroup ? getSegmentByCode(params.code, props.supergroups) : getSegmentByCode(params.code, props.segments);
  const [zoomLvl, setZoomLvl] = useState<number | undefined>(undefined);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  //const [mapAreas, setMapAreas] = useState<google.maps.LatLng[][][]>([]);
  const [mapAreas, setMapAreas] = useState<IMapPolygon[]>([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyARlhcBmHGJqLTzoE7piYcnEkqNtgYdlQI"
  });

  useEffect(() => {
    if(!isLoaded || zoomLvl === undefined) return;
    const currentOverlayLayer = getMapLayerByZoom(zoomLvl);

    const dataKey = `experian-map-${params.code}-${currentOverlayLayer}`;
    // caching layer data
    const ssMapAreas = mapLayerCache[dataKey];
    if(ssMapAreas !== undefined) {
      setMapAreas(ssMapAreas);
      minValue = mapLayerCache[dataKey + '-min'];
      maxValue = mapLayerCache[dataKey + '-max'];
      return;
    }

    (async () => {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/segments/${params.code}/map/${currentOverlayLayer}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        maxValue = 0;
        minValue = Infinity;
        const data: IMapArea[] = await response.json();
        const newMapAreas = data.map(mapArea => {
          if(mapArea.value > maxValue) maxValue = mapArea.value;
          if(mapArea.value < minValue) minValue = mapArea.value;
          return {
            value: mapArea.value,
            label: mapArea.label,
            paths: mapArea.geometry.map(
              polygon => polygon[0].map(
                coords => new google.maps.LatLng(coords[1], coords[0])
              )
            )
          };
        });
        //console.log(newMapAreas);
        setMapAreas(newMapAreas);
        mapLayerCache[dataKey] = newMapAreas;
        mapLayerCache[dataKey + '-min'] = minValue;
        mapLayerCache[dataKey + '-max'] = maxValue;
      }
    })();
  }, [params.code, isLoaded, zoomLvl]);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
    setZoomLvl(map.getZoom());
  }, []);
  
  if(props.segments.length === 0) return (
    <div className="SegmentPhotosPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <strong>Loading...</strong>
    </div>
  );

  // If segment is not in the list it probably doesn't exist
  if(segmentOverview === undefined) return <Page404 />;

  return (
    <div className="SegmentMapPage page page--no-top-padding">
      <SegmentPageNav segments={props.segments} />
      <SegmentPageTitleBar code={segmentOverview.code} name={segmentOverview.name} color={segmentOverview.primary_hex} />

      <section className="SegmentMapPage__section container page-section page-section--top-space">
        <div className="SegmentMapPage__content content-block grid">
          <div className="grid__col--span9">
            {isLoaded ? 
            <GoogleMap
              mapContainerStyle={{width: '100%', height: '600px'}}
              center={center}
              zoom={4}
              options={mapOptions}
              onLoad={onLoad}
              onZoomChanged={() => setZoomLvl(map !== undefined ? map.getZoom() : undefined)}
            >
              {mapAreas.map((area, aidx) => area.paths.map((p, pidx) => {
                //console.log(area.value, maxValue, (area.value / maxValue) * maxOpacity);
                let val = (area.value - minValue) / (maxValue - minValue);
                val = parseFloat((Math.round(val * 4) / 4).toFixed(2));
                //const color = getGradientColor(startColor, endColor, val);
                const options = Object.assign({}, polygonOptions, { fillOpacity: val * maxOpacity });
                //const options = Object.assign({}, polygonOptions, { fillColor: `rgb(${color.r}, ${color.g}, ${color.b})`});
                //console.log(options);
                return <Polygon 
                  key={aidx + '-' + pidx} 
                  paths={p} 
                  options={options}
                />;
              }))}
              {/*<Polygon path={polygonTestData} options={polygonOptions} />*/}
            </GoogleMap>: null}
          </div>

          <div className="SegmentMapPage__sidebar chart-block__filter-content grid__col--span3">
            <h5>Chart Options</h5>
            <div className="label-group">
              <div className="side-options__label">Zoom</div>
              <div className="side-options__label">Layer</div>
            </div>
            <div className="btn-group">
              <button className="btn">{zoomLvl}</button>
              <button className="SegmentMapPage__layer-name btn">{zoomLvl !== undefined ? getMapLayerByZoom(zoomLvl) : 'State'}</button>
            </div>
            <div className="side-options__label">Where we live</div>
            <div className="SegmentMapPage__scale-container">
              <div className="SegmentMapPage__scale"></div>
              <div className="SegmentMapPage__scale-labels">
                <div className="SegmentMapPage__scale-label">High</div>
                <div className="SegmentMapPage__scale-label">Low</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
