import React from 'react';

import './LoadingPage.css';

export default function LoadingPage() {
  return (
    <div className="LoadingPage page">
      <section className="container content-block">
        Loading...
      </section>
    </div>
  )
}
