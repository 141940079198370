import { 
  useState,
  useEffect,
  useMemo
} from 'react';
import { useHistory } from 'react-router-dom';

import Options from '../../Options';

/* Components */
import DataSelector from '../../components/DataSelector/DataSelector';
import SegmentCard from '../../components/SegmentCard/SegmentCard';
import Accordion from '../../elements/Accordion/Accordion';
import AccordionItem from '../../elements/Accordion/AccordionItem';
import ModalWindow from '../../elements/ModalWindow/ModalWindow';
import Tooltip from '../../elements/Tooltip/Tooltip';

import IVariableMeta from '../../interfaces/IVariableMeta';
import ISavedSearch from '../../interfaces/ISavedSearch';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import ILooseObject from '../../interfaces/ILooseObject';

import {ReactComponent as SearchIcon} from '../../images/feather-icons/search.svg';
import {ReactComponent as QuestionIcon} from '../../images/icon-tip-question.svg';

import './AudienceSearchPage.css'

interface ISearchOptions extends ILooseObject {
  kind: string,
  threshold: number
};

const defaultSearchOptions = {
  kind: 'group',
  threshold: 10
};

interface IAudienceSearchPageProps {
  segments: ISegmentBasic[],
  variablesList: IVariableMeta[]
};

export default function AudienceSearchPage(props: IAudienceSearchPageProps) {
  const history = useHistory();
  const [filterString, setFilterString] = useState<string>('');

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [savedSearches, setSavedSearches] = useState<ISavedSearch[] | null>(null);
  const [varsFilterString, setVarsFilterString] = useState<string>('');

  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedVariables, setSelectedVariables] = useState<number[]>([]);
  const [searchOptions, setSearchOptions] = useState<ISearchOptions>(Object.assign(defaultSearchOptions));

  useEffect(() => {
    if(savedSearches !== null) return;
    (async () => {
      const response = await fetch(`${Options.ApiBase}/wp-json/experian/saved-searches/all?timestamp=${Date.now()}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data: ISavedSearch[] = await response.json();
        setSavedSearches(data);
      }
    })();
  }, [savedSearches]);

  function addSelectedVariable(variableId: number) {
    const pos = selectedVariables.indexOf(variableId);
    if(pos !== -1) return; // no duplicates
    const newSelectedVariables = [variableId, ...selectedVariables];
    setSelectedVariables(newSelectedVariables);
  }

  function removeSelectedVariable(variableId: number) {
    const newSelectedVariables = [...selectedVariables];
    const pos = newSelectedVariables.indexOf(variableId);
    if(pos === -1) return;
    newSelectedVariables.splice(pos, 1);
    setSelectedVariables(newSelectedVariables);
  }

  function searchOptionChangeHandler(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
    const newSearchOptions: ISearchOptions = {...searchOptions};
    newSearchOptions[event.target.name] = event.target.value;
    setSearchOptions(newSearchOptions);
  }

  function searchSubmitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(selectedVariables.length < 1) {
      alert('You need to have at least 1 variable selected');
      return;
    }
    const path = `/identify/search?kind=${searchOptions.kind}&threshold=${searchOptions.threshold}&variables=${selectedVariables.join()}`;
    history.push(path);
    console.log(path);
  }

  function openSearch(s: ISavedSearch) {
    const path = `/identify/search?id=${s.id}&kind=${s.kind}&threshold=${s.threshold}&variables=${s.variables.join()}`;
    history.push(path);
  }

  function getSavedSearches() {
    if(savedSearches === null) return [];

    if(filterString !== '') {
      const filteredSearches = savedSearches.filter(s => s.name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1);
      return filteredSearches;
    }

    return savedSearches;
  }

  const filteredVariables = useMemo(() => {
    if(varsFilterString !== '') {
      const searchString = varsFilterString.toLowerCase();
      const result = props.variablesList.filter(v => 
        v.category.toLowerCase().indexOf(searchString) !== -1 ||
        v.topic.toLowerCase().indexOf(searchString) !== -1
      );
      return result;
    } 

    return props.variablesList;
  }, [props.variablesList, varsFilterString]);

  return (
    <div className="AudienceSearchPage page">
      <ModalWindow active={showSearch} onCloseBtnPress={() => setShowSearch(false)}>
        <div className="grid">
          <div className="grid__col--span8">
            <h2>New Audience Search</h2>
            <div className="grid grid--8col">
              <div className="grid__col--span4">
                <div className="AudienceSearchPage__variable-search search-field input-group">
                  <input className="search-field__input input input--small" name="filter-string" placeholder="Search..." value={varsFilterString} onChange={(e) => setVarsFilterString(e.target.value)} />
                  <button className="search-field__btn" type="submit"><SearchIcon /></button>
                </div>

                <div className="AudienceSearchPage__categories condensed-scroll">
                  <DataSelector variables={filteredVariables} selectedCategory='' selectedTopic='' selectedVariable={-1} showTopics={false} onCategorySelected={(c) => setSelectedCategory(c)} />
                </div>
              </div>
              <div className="AudienceSearchPage__topics-n-variables condensed-scroll grid__col--span4">
                <DataSelector variables={filteredVariables.filter(v => v.category === selectedCategory)} selectedCategory={selectedCategory} selectedTopic='' selectedVariable={-1} activeVariables={selectedVariables} showVariables={true} onVariableSelected={(v) => addSelectedVariable(v) } />
              </div>
            </div>
          </div>
          <div className="AudienceSearchPage__vars-n-form grid__col--span4">
            <h2 className="AudienceSearchPage__selection-summary-title">
              Selection Summary
              <button className="AudienceSearchPage__clear-variables-btn" onClick={() => setSelectedVariables([])}>Clear All</button>
            </h2>
            <ul className="AudienceSearchPage__selected-variables">
              {props.variablesList.filter(v => selectedVariables.indexOf(v.id) !== -1).map(variable =>
                <li key={variable.id} className="AudienceSearchPage__selected-variable tag" onClick={() => removeSelectedVariable(variable.id)}>{variable.topic}: {variable.variable}</li>)}
            </ul>
            <form className="AudienceSearchPage__form" onSubmit={searchSubmitHandler}>
              <div className="AudienceSearchPage__form-kind">
                <input className="radio" type="radio" name="kind" value="group" id="kind-group" checked={searchOptions.kind === 'group'} onChange={searchOptionChangeHandler} />
                <label className="radio__label" htmlFor="kind-group">Groups</label>

                <input className="radio" type="radio" name="kind" value="type" id="kind-type" checked={searchOptions.kind === 'type'} onChange={searchOptionChangeHandler} />
                <label className="radio__label" htmlFor="kind-type">Types</label>
              </div>
              <div className="AudienceSearchPage__threshold-container">
                <Tooltip text="The threshold co  ntrol enables you to increase or decrease audience size by changing the tolerance applied to the similarity score algorithm. Increasing the threshold will produce a smaller and more alike audience. Decreasing the threshold will produce a larger, yet less alike, audience." width={250}>
                  <QuestionIcon className="AudienceSearchPage__threshold-tooltip" />
                </Tooltip>
                <div className="AudienceSearchPage__threshold-label">Threshold: {searchOptions.threshold}</div>
                <input className="AudienceSearchPage__threshold-range range" type="range" name="threshold" min={-100} max={100} step={1} value={searchOptions.threshold} onChange={searchOptionChangeHandler} />
              </div>
              <div className="btn-group">
                <button className="btn btn--purple" type="submit">Run Report</button>
              </div>
            </form>
          </div>
        </div>
      </ModalWindow>
      <section className="AudienceSearchPage__section container page-section content-block grid">
        <div className="AudienceSearchPage__left-bar grid__col--span3">
          <button className="btn btn--large btn--purple" onClick={() => setShowSearch(true)}>New Audience Search <SearchIcon className="btn__icon btn__icon--right" /></button>
        </div>
        <div className="AudienceSearchPage__right-bar grid__col--span9">
          <div className="grid grid--9col">
            <div className="grid__col--span6">
              <h2>Recent Audience Searches</h2>
            </div>
            <div className="grid__col--span3">
              <div className="search-field input-group">
                <input className="search-field__input input input--small" name="filter-string" placeholder="Search saved searches" value={filterString} onChange={(e) => setFilterString(e.target.value)} />
                <button className="search-field__btn" type="submit"><SearchIcon /></button>
              </div>
            </div>
          </div>
          <Accordion>
          {getSavedSearches().map(s => {
            return (
              <AccordionItem key={s.id} title={s.name} extraBtnText="View Search" onExtraBtnPress={() => openSearch(s)}>
                <div className="grid grid--8col">
                  <div className="AudienceSearchPage__segments grid__col--span4">
                  {props.segments.filter(i => s.segments.indexOf(i.code) !== -1).map(segment => <SegmentCard className="AudienceSearchPage__segment" variant="search-preview" key={segment.id} segment={segment} />)}
                  </div>
                  <div className="grid__col--span4">
                  {props.variablesList.filter(v => s.variables.indexOf(v.id) !== -1).map(variable =>
                    <div key={variable.id} className="tag">{variable.topic}: {variable.variable}</div>)}
                  </div>
                </div>
              </AccordionItem>
            )
          })}
          </Accordion>
        </div>
      </section>
    </div>
  );
}
