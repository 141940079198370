import { useState, useEffect } from 'react';

import Options from '../../Options';
import { 
  fetchIt,
  getKeyFeatures,
  getTopicVariables,
  getLargestVariable
} from '../../Utils';

import VariableWidget from '../../components/VariableWidget/VariableWidget';

import ISegmentKeyFeatures from '../../interfaces/ISegmentKeyFeatures';
import ISegmentBasic from '../../interfaces/ISegmentBasic';
import { IVariableWidgetConfig, IVariableWidgetData } from '../../interfaces/IVariableWidget';
import VariableWidgetVariants from '../../enums/VariableWidgetVariants';

import './SearchResultSegment.css';

interface ISearchResultSegmetProps {
  segment: ISegmentBasic
};

export default function SearchResultSegment(props: ISearchResultSegmetProps) {
  const [heroImage, setHeroImage] = useState<string>(''); 
  const [keyFeatures, setKeyFeatures] = useState<ISegmentKeyFeatures | null>(null);

  const [whoWeAreWidgetsData, setWhoWeAreWidgetsData] = useState<IVariableWidgetData[] | null>(null);
  const [whoWeAreWidgets] = useState<IVariableWidgetConfig[]>([
    {
      variableName: 'Age',
      variant: VariableWidgetVariants.Large,
    },
    {
      variableName: 'Annual_Household_Income',
      variant: VariableWidgetVariants.Medium,
      annotation: 'per annum'
    },
    {
      variableName: 'Household_Composition',
      variant: VariableWidgetVariants.Large,
    },

    {
      variableName: 'Property-Value_Estimates',
      variant: VariableWidgetVariants.Default
    },
    {
      variableName: 'Property_Tenure',
      variant: VariableWidgetVariants.Large,
    },
    {
      variableName: 'Remoteness',
      variant: VariableWidgetVariants.Large,
    },
  ]);

  useEffect(() => {
    (async () => {
      // requesting all segment data here
      let response = await fetchIt(`${Options.ApiBase}/wp-json/experian/segments/${props.segment.code}/image`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const imageData: { url: string } = await response.json();
        setHeroImage(imageData.url);
      }

      setKeyFeatures(await getKeyFeatures(props.segment.code));
      await getWhoWeAreWidgets(props.segment.code, whoWeAreWidgets);
    })();
  }, [props.segment.code, whoWeAreWidgets]);

  async function getWhoWeAreWidgets(code : string, whoWeAreWidgets : IVariableWidgetConfig[]) {
    const promises : Promise<any>[] = [];
    
    whoWeAreWidgets.forEach((w, idx) => {
      promises.push(getTopicVariables(code, w.variableName));
    });

    const data : IVariableWidgetData[] = (await Promise.all(promises)).map(d => { return { variableData: d } });
    setWhoWeAreWidgetsData(data);
  }

  return (
    <div className="SearchResultSegment">
      <div className="SearchResultSegment__hero" style={{backgroundImage: heroImage !== '' ? `linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.3) 100%), url(${heroImage})` : undefined}}>
        <h1 className="SearchResultSegment__title heading--display">{props.segment.name}</h1>
        <h3 className="SearchResultSegment__description">{props.segment.description}</h3>
        <div className="SearchResultSegment__details">
          <h2 className="SearchResultSegment__code">{props.segment.code}</h2>
          <div className="SearchResultSegment__stats">
            <div className="SearchResultSegment__stat">
              <div className="SearchResultSegment__stat-value">{props.segment.household}%</div>
              of Population
            </div>
          </div>
        </div>
      </div>
      <div className="SearchResultSegment__content grid">
        <div className="grid__col--span3">
          <h5>Key Features</h5>
          { keyFeatures !== null ? 
            <ul className="SearchResultSegment__key-features">
              <li className="SearchResultSegment__key-feature">{keyFeatures.key_feature1}</li>
              <li className="SearchResultSegment__key-feature">{keyFeatures.key_feature2}</li>
              <li className="SearchResultSegment__key-feature">{keyFeatures.key_feature3}</li>
              <li className="SearchResultSegment__key-feature">{keyFeatures.key_feature4}</li>
              <li className="SearchResultSegment__key-feature">{keyFeatures.key_feature5}</li>
              <li className="SearchResultSegment__key-feature">{keyFeatures.key_feature6}</li>
            </ul>
          : null }
        </div>
        <div className="grid__col--span9 grid">
          {whoWeAreWidgetsData !== null ? whoWeAreWidgets.map((w, idx) => {
            //if(!(idx in whoWeAreWidgetsData)) return null;
            return (
              <div key={w.variableName} className="grid__col--span4">
                <VariableWidget data={getLargestVariable(whoWeAreWidgetsData[idx].variableData)} variant={w.variant} modifier="gray" />
              </div>
            )
          }) : null}
        </div>
      </div>
    </div>
  )
}
