import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

interface IProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean,
  authPath: string
};

export default function ProtectedRoute({isAuthenticated, authPath, ...routeProps}: IProtectedRouteProps) {
  if(isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: authPath }} />;
  }
}
