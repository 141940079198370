import React from 'react';

import PageContent from '../components/PageContent/PageContent';

export default function PrivacyPage() {
  return (
    <div className="PrivacyPage page">
      <section className="container content-block">
        <PageContent slug="privacy-statement" />
      </section>
    </div>
  );
}
