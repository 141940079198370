const ChartColors = [
  '#972D80',
  '#FFA31F',
  '#632678',
  '#008B8C',
  '#406EB3',
  '#B8337D',
  '#224d99',
  '#BB6BD9',
  '#9B51E0',
  '#632678',
  '#972D80',
  '#B8337D',
  '#EB5757',
  '#D35800',
  '#F2994A',
  '#27AE60',
  '#009318',
  '#56CCF2',
  '#00ACC3',
  '#008B8C',
  '#406EB3',
  '#005CC7',
  '#26478D',
  '#2F80ED',
  '#6FCF97',
  '#F2C94C'
];

export default ChartColors;
