import Chart from 'chart.js/auto';

import { getPluginOption } from './utils';
import { fontFamily } from '../Options';

const axisLabels = {
  id: 'expn_axist_labels',
  beforeDraw: function(chart: Chart) {
    const ctx = chart.ctx;
    // background
    const rectStart = chart.chartArea.top;
    const rectHeight = chart.chartArea.height;
    ctx.rect(chart.chartArea.left, rectStart, chart.chartArea.width, rectHeight);
    ctx.fillStyle = '#f5f5f5';
    ctx.fill();

    // legend labels
    ctx.font = `bold 16px ${fontFamily}`;
    ctx.fillStyle = '#111';
    ctx.textBaseline = 'middle';

    // TOP
    const top = getPluginOption(chart, 'axisLabels', 'top', null);
    if(top !== null) drawHorizontalScale(chart, top, chart.chartArea.top / 2);
    const bottom = getPluginOption(chart, 'axisLabels', 'bottom', null);
    if(bottom !== null) drawHorizontalScale(chart, bottom, chart.chartArea.bottom + (chart.height - chart.chartArea.bottom) / 2);

    const left = getPluginOption(chart, 'axisLabels', 'left', null);
    if(left !== null) drawVerticalScale(chart, left, chart.chartArea.left / 2);
    const right = getPluginOption(chart, 'axisLabels', 'right', null);
    if(right !== null) drawVerticalScale(chart, right, chart.chartArea.right + (chart.width - chart.chartArea.right) / 2);
  },
};

function drawVerticalScale(chart: Chart, options: any, leftPosition: number, sidePadding: number = 5) {
  const ctx = chart.ctx;

  if(options.top.label !== '') {
    ctx.textAlign = 'left';
    let topPos = chart.chartArea.bottom - sidePadding;
    if(options.top.align === 'center') {
      topPos = chart.chartArea.bottom - (chart.chartArea.height / 4);
      ctx.textAlign = 'center';
    }
    ctx.save();
    ctx.translate(leftPosition, topPos);
    if(options.top.flip !== undefined && options.top.flip === true) {
      ctx.rotate(Math.PI / 2);
    } else {
      ctx.rotate(-Math.PI / 2);
    }
    ctx.fillText(options.top.label, 0, 0);
    ctx.restore();
  }

  if(options.middle.label !== '') {
    const topPos = chart.chartArea.bottom - (chart.chartArea.height / 2);
    ctx.textAlign = 'center';
    ctx.save();
    ctx.translate(leftPosition, topPos);
    if(options.middle.flip !== undefined && options.middle.flip === true) {
      ctx.rotate(Math.PI / 2);
    } else {
      ctx.rotate(-Math.PI / 2);
    }
    ctx.fillText(options.middle.label, 0, 0);
    ctx.restore();
  }
  

  if(options.bottom.label !== '') {
    ctx.textAlign = 'right';
    let topPos = chart.chartArea.top + sidePadding;
    if(options.bottom.align === 'center') {
      topPos = chart.chartArea.top + (chart.chartArea.height / 4);
      ctx.textAlign = 'center';
    }
    ctx.save();
    ctx.translate(leftPosition, topPos);
    if(options.bottom.flip !== undefined && options.bottom.flip === true) {
      ctx.rotate(Math.PI / 2);
    } else {
      ctx.rotate(-Math.PI / 2);
    }
    ctx.fillText(options.bottom.label, 0, 0);
    ctx.restore();
  }
}

function drawHorizontalScale(chart: Chart, options: any, topPosition: number, sidePadding: number = 5) {
  const ctx = chart.ctx;

  if(options.left.label !== '') {
    ctx.textAlign = 'left';
    let leftPos = chart.chartArea.left + sidePadding;
    if(options.left.align === 'center') {
      leftPos = chart.chartArea.left + (chart.chartArea.width / 4);
      ctx.textAlign = 'center';
    }
    ctx.fillText(options.left.label, leftPos, topPosition);
  }

  if(options.middle.label !== '') {
    ctx.textAlign = 'center';
    ctx.fillText(options.middle.label, chart.chartArea.left + (chart.chartArea.width / 2), topPosition);
  }

  if(options.right.label !== '') {
    ctx.textAlign = 'right';
    let leftPos = chart.chartArea.left + chart.chartArea.width - sidePadding;
    if(options.right.align === 'center') {
      ctx.textAlign = 'center';
      leftPos = chart.chartArea.left + (chart.chartArea.width / 4) * 3;
    }
    ctx.fillText(options.right.label, leftPos, topPosition);
  }
}
export default axisLabels;
