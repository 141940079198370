import React, {
  useEffect,
  useState
} from 'react';

import Options from '../../Options';

import imageSliderInit from './scripts/image-slider';

import '../../styles/wp-blocks/image-slider.css';

interface IPageContentProps {
  slug: string
};

interface IWPPage {
  id: number,
  data: string,
  title: { rendered: string },
  content: { rendered: string }
};


export default function PageContent(props: IPageContentProps) {
  const rootRef: React.RefObject<HTMLDivElement> = React.createRef();
  const [scriptInitialized, setScriptsInitialized] = useState<boolean>(false);
  const [html, setHtml] = useState<string>(''); 

  useEffect(() => {
    (async () => {
      const response = await fetch(`${Options.ApiBase}/wp-json/wp/v2/pages?slug=${props.slug}`, { headers: Options.ApiHeaders });
      if(response.ok) {
        const data: IWPPage[] = await response.json();
        if(data.length > 0) {
          setHtml(data[0].content.rendered);
        } else {
          setHtml('Can\'t find the page content');
        }
      } else {
        setHtml('There was problem loading the page content');
      }
    })();
  }, [props.slug]);

  useEffect(() => {
    if(html === '' || rootRef.current === null) return;
    if(scriptInitialized !== false) return;
    imageSliderInit(rootRef); 

    setScriptsInitialized(true);
  }, [html, rootRef, scriptInitialized])

  return (
    <div className="PageContent" ref={rootRef} dangerouslySetInnerHTML={{ __html: html }}></div>
  );
}
