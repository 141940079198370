import { useEffect, useRef } from 'react';

import Options from './Options';

import ISegmentBasic from './interfaces/ISegmentBasic';
import ISuperGroupBasic from './interfaces/ISuperGroupBasic';
import ITopicVariable from './interfaces/ITopicVariable';
import ITopicAxis from './interfaces/ITopicAxis';
import IVariableCompareData from './interfaces/IVariableCompareData';
import ISegmentKeyFeatures from './interfaces/ISegmentKeyFeatures';
import ISegmentOverviewData from './interfaces/ISegmentOverviewData';

export function sortByKey(a : any, b : any, sortKey : string) {
  if(sortKey === '') return 0; 
  if(a[sortKey] > b[sortKey]) {
    return -1;
  } else if(a[sortKey] < b[sortKey]) {
    return 1;
  }
  return 0;
}

export function delay(miliseconds: number) {
  return new Promise(function(resolve, reject) {
    setTimeout(resolve, miliseconds);
  });
}

export async function fetchIt(url: string, options: object, delayMs = 1000, maxAttempts = 5) {
  let response = null;
  let attempts = 0;
  do {
    if(attempts !== 0) await delay(delayMs);
    if(attempts >= maxAttempts) throw new Error('Can\'t fetch request');
    attempts++;

    response = await fetch(url, options);
  } while(!response.ok && response.status === 429);

  return response;
}

export async function getOverviewData(code : string) {
  const [
    age,
    annualHouseholdIncome,
    propertyTenure,
    remoteness,
  ] = await Promise.all([
    getTopicVariables(code, 'Age'),
    getTopicVariables(code, 'Annual Household Income'),
    getTopicVariables(code, 'Property Tenure'),
    getTopicVariables(code, 'Remoteness')
  ]);

  const newOverviewData : ISegmentOverviewData = {
    age, annualHouseholdIncome, propertyTenure, remoteness
  };
  return newOverviewData;
}

export function getLargestVariableValue(variables : ITopicVariable[], key : string = 'mean') {
    return getLargestVariable(variables, key).variable;
}

export function getLargestVariable(variables : ITopicVariable[], key : string = 'mean') {
    return variables.filter(a => a.mean <= 100).sort((a : ITopicVariable, b : ITopicVariable) => sortByKey(a, b, key))[0];
}

export function getSegmentByCode(code: string, segments: ISegmentBasic[] | ISuperGroupBasic[]) {
  const segment : ISegmentBasic | undefined = segments.find(s => s.code === code);
  return segment;
}

export async function getTopicVariables(code : string, topic : string) {
  const response = await fetchIt(`${Options.ApiBase}/wp-json/experian/topics/${code}/${topic.replace(/\s/g, '_')}`, { headers: Options.ApiHeaders });
  if(response.ok) {
    const vars : ITopicVariable[] = await response.json();
    return vars;
  }
  return [] as ITopicVariable[];
}

export async function getVariableCompareData(variableId: number) {
  const response = await fetch(`${Options.ApiBase}/wp-json/experian/compare-variable/${variableId}`, { headers: Options.ApiHeaders });
  if(response.ok) {
    const data : IVariableCompareData = await response.json();
    return data;
  }
  return null;
}

export async function getTopicAxis(topic: string) {
  const response = await fetch(`${Options.ApiBase}/wp-json/experian/topic-axis/${topic.replace(/\s/g, '_')}`, { headers: Options.ApiHeaders });
  if(response.ok) {
    const vars : ITopicAxis = await response.json();
    return vars;
  }
  return null;
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      if(savedCallback.current !== undefined) savedCallback.current();
    }
    if(delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export async function getKeyFeatures(code : string) {
  const response = await fetchIt(`${Options.ApiBase}/wp-json/experian/segments/${code}/key-features`, { headers: Options.ApiHeaders });
  if(response.ok) {
    const features : ISegmentKeyFeatures = await response.json();
    return features;
  }
  return null;
}

export function exportPdf() {
    alert('To export please select Destination: "Save as PDF" in your browser print dialog');
    window.print();
}
